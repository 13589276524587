import { injectable, singleton } from 'tsyringe'
import { action, makeObservable, observable } from 'mobx'
import type { UserResponse } from '@repo/contracts'

@singleton()
@injectable()
export class MeStore {
  @observable me: UserResponse | null = null

  constructor() {
    makeObservable(this)
  }

  @action.bound
  setMe(me: UserResponse) {
    this.me = me
  }

  @action.bound
  clean() {
    this.me = null
  }
}
