import { ButtonTheme, ButtonVariant } from './types'

export const DEFAULT_BUTTON_VARIANT_CLASSNAMES: Record<ButtonVariant, string> = {
  filled: 'border text-white disabled:bg-gray-300 disabled:border-gray-300',
  outline: 'border disabled:bg-gray-200 disabled:border-gray-200',
  text: 'border-0 p-0',
}

export const DEFAULT_BUTTON_THEME_CLASSNAMES: Record<ButtonTheme, string> = {
  primary: '',
  secondary: '',
  danger: 'border-red-500 hover:border-red-400 text-red-500 hover:text-red-400',
}
