"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseResponse = exports.MessageResponse = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.MessageResponse = typebox_1.Type.Object({
    message: typebox_1.Type.String(),
});
const BaseResponse = (entity) => typebox_1.Type.Composite([
    exports.MessageResponse,
    typebox_1.Type.Object({
        data: entity,
    }),
]);
exports.BaseResponse = BaseResponse;
