import { useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { container } from 'tsyringe'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'src/shared/constants/auth.ts'
import { useLocalStorage } from 'src/shared/hooks/useLocalStorage.ts'
import { AxiosError } from 'axios'
import { apiClient } from '../api/clients'
import { useService } from 'src/shared/hooks/di'
import { AuthAPI } from 'src/shared/api/auth/api'
import ROUTES from 'src/app/router/constants'
import { MeStore } from 'src/modules/UsersModule/User/stores/MeStore'

const useApiInterceptors = () => {
  const { clean } = container.resolve(MeStore)
  const queryClient = useQueryClient()
  const { removeItem } = useLocalStorage()
  const navigate = useNavigate()
  const isRefreshing = useRef(false)
  const authAPI = useService(AuthAPI)

  const onUnauthorized = useCallback(async () => {
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_KEY)

    if (!refreshToken) {
      throw new Error('There is no refresh token')
    }

    const response = await authAPI.refreshToken(refreshToken)
    window.localStorage.setItem(ACCESS_TOKEN_KEY, response.data.accessToken)
    window.localStorage.setItem(REFRESH_TOKEN_KEY, response.data.refreshToken)
    apiClient.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`

    return response.data.accessToken
  }, [authAPI])

  useEffect(() => {
    const requestInterceptor = apiClient.interceptors.request.use((config) => {
      const token = window.localStorage.getItem(ACCESS_TOKEN_KEY)
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }

      return config
    })

    const responseInterceptor = apiClient.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        if (error.response) {
          switch (error.response.status) {
            case 401: {
              if (isRefreshing.current) {
                return Promise.reject(error)
              }

              isRefreshing.current = true
              queryClient.setDefaultOptions({ queries: { enabled: false } })

              return onUnauthorized()
                .then((accessToken) => {
                  isRefreshing.current = false
                  queryClient.setDefaultOptions({ queries: { enabled: true } })

                  if (!error.config) {
                    return Promise.reject('There is nothing to refetch')
                  }

                  error.config.headers.Authorization = `Bearer ${accessToken}`

                  return apiClient.request(error.config).then((response) => {
                    return Promise.resolve(response)
                  })
                })
                .catch(() => {
                  isRefreshing.current = false
                  removeItem(REFRESH_TOKEN_KEY)
                  removeItem(ACCESS_TOKEN_KEY)
                  clean()
                  queryClient.setDefaultOptions({ queries: { enabled: true } })
                  navigate(ROUTES.AUTH.SIGN_IN)
                })
            }
            case 403: {
              navigate(ROUTES.FORBIDDEN)
              break
            }
          }
        }

        return Promise.reject(error)
      },
    )

    return () => {
      apiClient.interceptors.request.eject(requestInterceptor)
      apiClient.interceptors.response.eject(responseInterceptor)
    }
  }, [clean, navigate, onUnauthorized, queryClient, removeItem])
}

export default useApiInterceptors
