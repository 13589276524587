import ROUTES from '../constants'
import { lazy, Suspense } from 'react'
import ModuleSpinner from '@repo/ui/ModuleSpinner'

const RolesListPage = lazy(() => import('src/pages/RolesPages/RolesListPage'))
const RoleDetailsPage = lazy(() => import('src/pages/RolesPages/RoleDetailsPage'))

export default [
  {
    path: ROUTES.ROLES.LIST,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <RolesListPage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.ROLES.DETAILS,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <RoleDetailsPage />
      </Suspense>
    ),
  },
]
