import { RoleFindParamsType, RoleIdsQueryParamsType, UsersByRoleFindParamsSchemaType } from '@repo/contracts'
import { QueryKey } from '@tanstack/react-query'

export const ROLES_QUERY_KEYS = {
  root: ['roles'] as QueryKey,
  findAll: function () {
    return this.root.concat('find')
  },
  find: function (params: RoleFindParamsType) {
    return this.root.concat('find', params)
  },
  getAll: function () {
    return this.root.concat('get')
  },
  get: function (id: number) {
    return this.root.concat('get', id)
  },
}

export const ROLE_PERMISSIONS_QUERY_KEYS = {
  root: ['role-permissions'] as QueryKey,
  self: ['self-roles', 'role-permissions'],
  findAll: function () {
    return this.root.concat('find')
  },
  find: function (params: RoleIdsQueryParamsType) {
    return this.root.concat('find', params)
  },
}

export const ROLE_USERS_QUERY_KEYS = {
  root: ['role-users'] as QueryKey,
  getAll: function () {
    return this.root.concat('get')
  },
  get: function (id: number, params: UsersByRoleFindParamsSchemaType) {
    return this.root.concat('get', id, params)
  },
}
