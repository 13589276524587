import { Component, ReactNode, ErrorInfo } from 'react'

export interface ErrorBoundaryProps {
  children: ReactNode
  title?: string
}

interface State {
  hasError: boolean
  error: Error | null
  errorInfo: ErrorInfo | null
}

class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, error: null, errorInfo: null }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      hasError: true,
      error,
      errorInfo,
    })
    console.error('Uncaught error:', error, errorInfo)
  }

  render() {
    const {
      state: { hasError, error, errorInfo },
      props: { title = 'Something went wrong', children },
    } = this

    if (hasError) {
      return (
        <div>
          <p>{title}</p>
          <p>{error?.message}</p>
          <p>{errorInfo?.componentStack}</p>
        </div>
      )
    }

    return children
  }
}

export { ErrorBoundary }
