export const LogoIcon = ({ ...props }) => {
  return (
    <svg width={162} height={34} viewBox="0 0 162 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13.0272 20.4262L19.4166 16.9628L17 11.6078L13.0272 20.4262Z" fill="#C8A0FD" />
      <path
        d="M20.5814 19.5439L11.1169 24.666H8.00767L15.7141 7.58834H18.2922L28.294 29.7011C31.7946 26.5887 34 22.0506 34 17C34 7.61319 26.3899 0 17 0C7.61319 0 0 7.61009 0 17C0 26.3868 7.61009 34 17 34C20.2925 34 23.3676 33.0588 25.9706 31.4374L20.5814 19.5439Z"
        fill="#C8A0FD"
      />
      <path d="M73.8334 4.51025L76.2127 13.2851H77.7782L75.4796 4.51025H73.8334Z" fill="#222325" />
      <path
        d="M70.6402 10.4182L69.0934 4.51025H67.4471L69.8233 13.2851H71.4572L73.8334 4.51025H72.1871L70.6402 10.4182Z"
        fill="#222325"
      />
      <path d="M63.4992 13.2851H65.0678L67.4471 4.51025H65.8008L63.4992 13.2851Z" fill="#222325" />
      <path
        d="M83.5308 4.40466C80.903 4.40466 78.7722 6.44851 78.7722 8.97071C78.7722 11.4929 80.903 13.5368 83.5308 13.5368C86.1586 13.5368 88.2894 11.4929 88.2894 8.97071C88.2894 6.44851 86.1586 4.40466 83.5308 4.40466ZM83.5308 12.2073C81.8504 12.2073 80.4868 10.7568 80.4868 8.97071C80.4868 7.18467 81.8504 5.7341 83.5308 5.7341C85.2112 5.7341 86.5748 7.18467 86.5748 8.97071C86.5748 10.7568 85.2112 12.2073 83.5308 12.2073Z"
        fill="#222325"
      />
      <path
        d="M124.933 4.40466C122.094 4.40466 120.491 6.44851 120.491 8.97071C120.491 11.4929 122.261 13.5368 124.933 13.5368C127.259 13.5368 128.753 12.1638 129.219 10.2815H127.53C127.088 11.4184 126.091 12.2104 124.933 12.2104C123.464 12.2104 122.252 10.9369 122.109 9.30308H129.365C129.371 9.19436 129.375 9.08254 129.375 8.97382C129.371 6.44852 127.772 4.40466 124.933 4.40466ZM122.199 8.09478C122.535 6.73118 123.628 5.73099 124.933 5.73099C126.237 5.73099 127.331 6.73118 127.666 8.09478H122.199Z"
        fill="#222325"
      />
      <path d="M55.0629 5.90181H58.3088V13.2851H60.0296V5.90181H63.2724V4.51025H55.0629V5.90181Z" fill="#222325" />
      <path
        d="M149.276 8.04817H144.203V4.51025H142.479V13.2851H144.203V9.30616H149.276V13.2851H150.997V4.51025H149.276V8.04817Z"
        fill="#222325"
      />
      <path
        d="M136.394 4.40466C134.919 4.40466 133.621 5.19363 132.844 6.39571V4.64073H131.123V15.6179H132.844V11.5706C133.621 12.7727 134.919 13.5616 136.394 13.5616C138.774 13.5616 140.7 11.5116 140.7 8.98625C140.7 6.45162 138.771 4.40466 136.394 4.40466ZM135.957 12.2291C134.236 12.2291 132.841 10.7785 132.841 8.99246C132.841 7.20331 134.236 5.75584 135.957 5.75584C137.677 5.75584 139.072 7.20642 139.072 8.99246C139.072 10.7816 137.677 12.2291 135.957 12.2291Z"
        fill="#222325"
      />
      <path
        d="M114.403 4.40466C112.927 4.40466 111.629 5.19363 110.853 6.39571V4.64073H109.132V6.40192C108.355 5.19674 107.054 4.40466 105.575 4.40466C103.196 4.40466 101.27 6.45473 101.27 8.98004C101.27 11.5084 103.199 13.5554 105.575 13.5554C107.054 13.5554 108.355 12.7633 109.132 11.5581V15.6148H110.853V11.5675C111.629 12.7695 112.927 13.5585 114.403 13.5585C116.782 13.5585 118.708 11.5084 118.708 8.98314C118.711 6.45162 116.782 4.40466 114.403 4.40466ZM106.013 12.2291C104.292 12.2291 102.898 10.7785 102.898 8.99246C102.898 7.20331 104.292 5.75584 106.013 5.75584C107.734 5.75584 109.129 7.20642 109.129 8.99246C109.126 10.7816 107.731 12.2291 106.013 12.2291ZM113.968 12.2291C112.247 12.2291 110.853 10.7785 110.853 8.99246C110.853 7.20331 112.247 5.75584 113.968 5.75584C115.689 5.75584 117.084 7.20642 117.084 8.99246C117.084 10.7816 115.689 12.2291 113.968 12.2291Z"
        fill="#222325"
      />
      <path
        d="M94.831 5.73099C96.2039 5.73099 97.3656 6.70012 97.7446 8.03266H99.4902C99.0398 5.96085 97.1264 4.40466 94.831 4.40466C92.2032 4.40466 90.0724 6.44851 90.0724 8.97071C90.0724 11.4929 92.2032 13.5368 94.831 13.5368C97.1233 13.5368 99.0398 11.9806 99.4902 9.90877H97.7446C97.3656 11.2382 96.207 12.2104 94.831 12.2104C93.1506 12.2104 91.787 10.7599 91.787 8.97382C91.787 7.18778 93.1506 5.73099 94.831 5.73099Z"
        fill="#222325"
      />
      <path
        d="M48.6611 0.658569L51.264 7.71886H46.055L48.6611 0.658569H46.9372L42.2313 13.2851H44.0018L45.5332 9.13838H51.789L53.3203 13.2851H55.0908L50.385 0.658569H48.6611Z"
        fill="#222325"
      />
      <path
        d="M161.471 13.2852L161.467 8.34017C161.467 6.1907 160.669 4.88923 159.215 4.38292C158.156 4.01329 155.559 3.8176 154.09 5.19052C153.36 5.87077 153.183 6.75913 153.081 7.1971H154.944C155.09 6.59451 155.597 5.70304 157.346 5.70304C159.862 5.70304 159.865 7.5512 159.865 7.5512C159.865 7.5512 157.367 7.64439 155.991 7.78727C153.264 8.06682 152.779 9.79385 152.779 10.7412C152.779 11.4028 152.792 13.5616 156.131 13.5616C158.697 13.5616 159.588 11.8346 159.865 11.0332V13.2883H161.471V13.2852ZM158.507 11.2786C157.395 12.198 156.383 12.0676 156.078 12.0676C155.774 12.0676 154.398 11.9278 154.398 10.676C154.398 9.42422 155.976 9.19436 157.557 9.08254C158.976 8.98314 159.61 9.01731 159.61 9.01731C159.61 9.01731 159.619 10.3592 158.507 11.2786Z"
        fill="#222325"
      />
      <path d="M80.698 20.6963L83.0773 29.4743H84.6428L82.3442 20.6963H80.698Z" fill="#222325" />
      <path d="M76.7531 29.4743H78.3218L80.698 20.6963H79.0517L76.7531 29.4743Z" fill="#222325" />
      <path
        d="M71.0192 20.5316C68.3914 20.5316 66.2606 22.5755 66.2606 25.0977C66.2606 27.6199 68.3914 29.6637 71.0192 29.6637C73.647 29.6637 75.7778 27.6199 75.7778 25.0977C75.7778 22.5755 73.647 20.5316 71.0192 20.5316ZM71.0192 28.3343C69.3388 28.3343 67.9752 26.8837 67.9752 25.0977C67.9752 23.3085 69.3388 21.8611 71.0192 21.8611C72.6996 21.8611 74.0632 23.3116 74.0632 25.0977C74.0632 26.8837 72.6996 28.3343 71.0192 28.3343Z"
        fill="#222325"
      />
      <path
        d="M63.8005 20.7087L61.3715 24.4672H59.5979V20.7087H57.8739V29.4836H59.5979V25.7252H61.3125L63.7943 29.4836H65.5399L62.7071 25.0915L65.5399 20.7087H63.8005Z"
        fill="#222325"
      />
      <path
        d="M94.6322 29.4743L94.6291 24.5293C94.6291 22.3798 93.8308 21.0783 92.3771 20.572C91.3179 20.2024 88.7212 20.0067 87.252 21.3796C86.522 22.0599 86.345 22.9482 86.2425 23.3862H88.1062C88.2521 22.7836 88.7585 21.8921 90.5072 21.8921C93.0232 21.8921 93.0263 23.7403 93.0263 23.7403C93.0263 23.7403 90.529 23.8335 89.1529 23.9764C86.4257 24.2559 85.9412 25.9829 85.9412 26.9303C85.9412 27.5919 85.9536 29.7507 89.2927 29.7507C91.8584 29.7507 92.7499 28.0237 93.0263 27.2223V29.4774H94.6322V29.4743ZM91.672 27.4677C90.56 28.3871 89.5474 28.2566 89.243 28.2566C88.9386 28.2566 87.5626 28.1169 87.5626 26.8651C87.5626 25.6133 89.1405 25.3834 90.7215 25.2716C92.1411 25.1722 92.7747 25.2064 92.7747 25.2064C92.7747 25.2064 92.784 26.5451 91.672 27.4677Z"
        fill="#222325"
      />
      <path
        d="M54.2428 28.0517H49.9346V16.8198H48.2293V28.0517H43.9304V16.8198H42.2251V29.4836H55.9481V16.8198H54.2428V28.0517Z"
        fill="#222325"
      />
    </svg>
  )
}
