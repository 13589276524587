import {
  CreateUserPayloadType,
  FindParamsArguments,
  ResolvedUsersFindResponseType,
  StudentFindParamsType,
  StudentFindResponseType,
  StudentTransferPayload,
  UserBlockUnblockParamsSchemaType,
  UserInviteStaffParamsSchemaType,
  UserInviteStaffResponseSchemaType,
  UserResponse,
} from '@repo/contracts'
import { apiClient } from 'src/shared/api/clients'
import { injectable, singleton } from 'tsyringe'

@singleton()
@injectable()
export class UsersAPI {
  constructor() {}

  me() {
    return apiClient.get<UserResponse>('user/me')
  }

  findUsers(params: FindParamsArguments) {
    return apiClient.post<ResolvedUsersFindResponseType>('user/find', params)
  }

  findStudents(params: StudentFindParamsType) {
    return apiClient.post<StudentFindResponseType>('user/students/find', params)
  }

  createUser(params: CreateUserPayloadType) {
    return apiClient.post('user', params)
  }

  inviteUser(params: UserInviteStaffParamsSchemaType) {
    return apiClient.post<UserInviteStaffResponseSchemaType>('user/invite/staff', params)
  }

  blockUsers(params: UserBlockUnblockParamsSchemaType) {
    return apiClient.post('/user/block', params)
  }

  unblockUsers(params: UserBlockUnblockParamsSchemaType) {
    return apiClient.post('/user/unblock', params)
  }

  transferStudents(data: StudentTransferPayload) {
    return apiClient.post('/user/students/transfer', data)
  }
}
