"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OneOf = exports.MakeNullable = exports.MakeFilterable = exports.MakeRequired = exports.PickMakeOptional = exports.MakeOptional = void 0;
const typebox_1 = require("@sinclair/typebox");
const MakeOptional = (schema, keys) => {
    if (keys) {
        const picked = typebox_1.Type.Pick(schema, keys);
        return typebox_1.Type.Composite([typebox_1.Type.Omit(schema, keys), typebox_1.Type.Partial(picked)]);
    }
    return typebox_1.Type.Composite([typebox_1.Type.Omit(schema, typebox_1.Type.KeyOf(schema)), typebox_1.Type.Partial(typebox_1.Type.Pick(schema, typebox_1.Type.KeyOf(schema)))]);
};
exports.MakeOptional = MakeOptional;
const PickMakeOptional = (schema, keys) => {
    return typebox_1.Type.Partial(typebox_1.Type.Pick(schema, keys));
};
exports.PickMakeOptional = PickMakeOptional;
const MakeRequired = (schema, keys) => {
    return typebox_1.Type.Composite([typebox_1.Type.Omit(schema, keys), typebox_1.Type.Required(typebox_1.Type.Pick(schema, keys))]);
};
exports.MakeRequired = MakeRequired;
const MakeFilterable = (schema, keys, options, compositeSchema) => {
    return typebox_1.Type.Partial(typebox_1.Type.Intersect([
        typebox_1.Type.Mapped(typebox_1.Type.KeyOf(typebox_1.Type.Partial(typebox_1.Type.Pick(schema, keys))), (K) => typebox_1.Type.Index(schema, K, options)),
        compositeSchema || typebox_1.Type.Unknown(),
    ]));
};
exports.MakeFilterable = MakeFilterable;
const MakeNullable = (schema, keys) => {
    if (keys) {
        const picked = typebox_1.Type.Pick(schema, keys);
        return typebox_1.Type.Composite([
            typebox_1.Type.Omit(schema, keys),
            typebox_1.Type.Mapped(typebox_1.Type.KeyOf(picked), (K) => typebox_1.Type.Union([typebox_1.Type.Index(picked, K), typebox_1.Type.Null()])),
        ]);
    }
    return typebox_1.Type.Mapped(typebox_1.Type.KeyOf(schema), (K) => typebox_1.Type.Union([typebox_1.Type.Index(schema, K), typebox_1.Type.Null()]));
};
exports.MakeNullable = MakeNullable;
const OneOf = (oneOf, options = {}) => {
    return typebox_1.Type.Unsafe({ ...options, oneOf });
};
exports.OneOf = OneOf;
