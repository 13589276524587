export const s3FileUrlBuilder = (fileName: string): string => {
  const bucketName = import.meta.env.VITE_S3_BUCKET_NAME
  const region = import.meta.env.VITE_S3_REGION
  const endpoint = import.meta.env.VITE_S3_ENDPOINT

  const index = endpoint.indexOf('.')
  const beginning = endpoint.slice(0, index)
  const end = endpoint.slice(index)

  return `https://${bucketName}.${beginning}-${region}${end}/${fileName}`
}
