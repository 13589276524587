"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudyPlanCoursesFindResponseSchema = exports.StudyPlanCoursesFindParamsSchema = exports.StudyPlanCoursesFindOrderSchema = exports.StudyPlanCoursesFindFilterParamsSchema = exports.StudyPlanCourseSchema = exports.StudyPlanUpdateStatusSchema = exports.StudyPlanAttachDetachSchema = exports.StudyPlanParamsSchema = exports.StudyPlanFindResponseSchema = exports.StudyPlanExtendedSchemaResponse = exports.StudyPlanFindParamsSchema = exports.StudyPlanFindOrderSchema = exports.StudyPlanFindExcludeParamsSchema = exports.StudyPlanFindFilterParamsSchema = exports.StudyPlanSchemaResponse = exports.StudyPlanUpdateSchema = exports.StudyPlanCreateFormSchema = exports.StudyPlanCreateSchema = exports.StudyPlanSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const baseEntity_1 = require("../baseEntity");
const responses_1 = require("../../responses");
const course_1 = require("../course");
const common_1 = require("../../common");
exports.StudyPlanSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: typebox_1.Type.String({ minLength: 2, maxLength: 100 }),
    status: typebox_1.Type.Enum(course_1.COURSE_STATUSES, { default: course_1.COURSE_STATUSES.ACTIVE }),
}, { additionalProperties: false });
exports.StudyPlanCreateSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.StudyPlanSchema, ['id', 'status']),
    typebox_1.Type.Object({
        version_ids: (0, common_1.Nullable)(typebox_1.Type.Array(typebox_1.Type.Integer())),
        status: typebox_1.Type.Optional(exports.StudyPlanSchema.properties.status),
    }),
]);
exports.StudyPlanCreateFormSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.StudyPlanCreateSchema, ['courses', 'status', 'version_ids']),
    typebox_1.Type.Object({
        status: typebox_1.Type.Optional(common_1.FormLookupOption),
    }),
]);
exports.StudyPlanUpdateSchema = typebox_1.Type.Pick(exports.StudyPlanSchema, ['name']);
exports.StudyPlanSchemaResponse = typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.StudyPlanSchema], {
    additionalProperties: false,
});
exports.StudyPlanFindFilterParamsSchema = typebox_1.Type.Object({
    name: typebox_1.Type.Optional(typebox_1.Type.String()),
    version_id: typebox_1.Type.Optional(typebox_1.Type.Integer()),
}, { additionalProperties: false });
exports.StudyPlanFindExcludeParamsSchema = typebox_1.Type.Object({
    course_id: typebox_1.Type.Optional(typebox_1.Type.Integer()),
});
exports.StudyPlanFindOrderSchema = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.StudyPlanSchemaResponse, [
    'name',
    'created_at',
    'status',
]));
exports.StudyPlanFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.StudyPlanFindFilterParamsSchema,
    exclude: exports.StudyPlanFindExcludeParamsSchema,
    order: exports.StudyPlanFindOrderSchema,
});
exports.StudyPlanExtendedSchemaResponse = typebox_1.Type.Composite([
    exports.StudyPlanSchemaResponse,
    typebox_1.Type.Object({
        age_groups_count: typebox_1.Type.Integer(),
        courses_count: typebox_1.Type.Integer(),
    }),
]);
exports.StudyPlanFindResponseSchema = (0, responses_1.PaginatedList)(exports.StudyPlanExtendedSchemaResponse);
exports.StudyPlanParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
});
exports.StudyPlanAttachDetachSchema = typebox_1.Type.Object({
    version_id: typebox_1.Type.Integer(),
    study_plan_ids: typebox_1.Type.Array(typebox_1.Type.Integer()),
});
exports.StudyPlanUpdateStatusSchema = typebox_1.Type.Object({
    status: typebox_1.Type.Enum(course_1.COURSE_STATUSES),
}, { additionalProperties: false });
exports.StudyPlanCourseSchema = typebox_1.Type.Object({
    course_id: typebox_1.Type.Integer(),
    course_name: typebox_1.Type.String(),
    course_status: typebox_1.Type.Enum(course_1.COURSE_STATUSES),
    version_id: typebox_1.Type.Integer(),
    version_name: (0, common_1.Nullable)(typebox_1.Type.String()),
    grade: (0, common_1.Nullable)(typebox_1.Type.Integer()),
});
exports.StudyPlanCoursesFindFilterParamsSchema = typebox_1.Type.Partial(typebox_1.Type.Object({
    course_name: typebox_1.Type.String({ minLength: 1, maxLength: 50 }),
}));
exports.StudyPlanCoursesFindOrderSchema = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.StudyPlanCourseSchema, ['course_status']));
exports.StudyPlanCoursesFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.StudyPlanCoursesFindFilterParamsSchema,
    order: exports.StudyPlanCoursesFindOrderSchema,
});
exports.StudyPlanCoursesFindResponseSchema = (0, responses_1.PaginatedList)(exports.StudyPlanCourseSchema);
