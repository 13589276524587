import { HomeIcon, WrenchIcon, CalendarIcon, ListBulletIcon, BookOpenIcon } from '@heroicons/react/24/outline'
import { MenuItemType } from './MenuTypes'
import { DotIcon } from '@repo/ui/icons'
import { MenuList } from '../MenuList/Menu'
import { MenuListItem } from 'src/components/MenuListItem'
import ROUTES from 'src/app/router/constants'

const menuItems: MenuItemType[] = [
  {
    path: ROUTES.HOME,
    icon: <HomeIcon />,
    label: 'home',
  },
  {
    path: ROUTES.CALENDAR,
    icon: <CalendarIcon />,
    label: 'calendar',
  },
  {
    path: 'coursesMenu',
    icon: <ListBulletIcon />,
    label: 'courses',
    permissions: {
      CoursesManagement: ['read', 'edit'],
    },
    child: [
      {
        path: ROUTES.COURSES.LIST,
        icon: <DotIcon />,
        label: 'courses',
        permissions: {
          CoursesManagement: ['read', 'edit'],
        },
      },
      {
        path: ROUTES.STUDY_PLANS.LIST,
        icon: <DotIcon />,
        label: 'studyPlans',
        permissions: {
          CoursesManagement: ['read', 'edit'],
        },
      },
      {
        path: ROUTES.BANK_QUESTION.LIST,
        icon: <DotIcon />,
        label: 'bankQuestions',
        permissions: {
          CoursesManagement: ['read', 'edit'],
        },
      },
    ],
  },
  {
    path: 'settingsMenu',
    icon: <WrenchIcon />,
    label: 'settings',
    permissions: {
      RolesAndPermissionsManagement: ['read', 'edit'],
      StaffPositionManagement: ['read', 'edit'],
    },
    child: [
      {
        path: ROUTES.USERS.LIST,
        icon: <DotIcon />,
        label: 'users',
        permissions: {
          StaffPositionManagement: ['read', 'edit'],
        },
      },
      {
        path: ROUTES.ROLES.LIST,
        icon: <DotIcon />,
        label: 'roles',
        permissions: {
          RolesAndPermissionsManagement: ['read', 'edit'],
        },
      },
      {
        path: ROUTES.POSITIONS.LIST,
        icon: <DotIcon />,
        label: 'positions',
        permissions: {
          StaffPositionManagement: ['read', 'edit'],
        },
      },
      {
        path: ROUTES.AGE_GROUPS.LIST,
        icon: <DotIcon />,
        label: 'ageGroups',
        permissions: {
          CoursesManagement: ['read', 'edit'],
        },
      },
      {
        path: ROUTES.PROMOCODES.LIST,
        icon: <DotIcon />,
        label: 'promoCodes',
        permissions: {
          CoursesManagement: ['read', 'edit'],
        },
      },
    ],
  },
  {
    path: ROUTES.DICTIONARIES,
    icon: <BookOpenIcon />,
    label: 'directories',
    // TODO: mb need to change namespaces in future
    permissions: {
      CoursesManagement: ['read', 'edit'],
    },
  },
]

export const Menu = () => {
  return (
    <MenuList>
      {menuItems.map((mi) => (
        <MenuListItem key={mi.path} item={mi} />
      ))}
    </MenuList>
  )
}
