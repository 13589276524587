import { SVGProps } from 'react'

const SpinnerIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M160 80C160 124.183 124.183 160 80 160C35.8172 160 0 124.183 0 80C0 35.8172 35.8172 0 80 0C124.183 0 160 35.8172 160 80ZM14.5303 80C14.5303 116.158 43.8421 145.47 80 145.47C116.158 145.47 145.47 116.158 145.47 80C145.47 43.8421 116.158 14.5303 80 14.5303C43.8421 14.5303 14.5303 43.8421 14.5303 80Z"
        fill="#E5E7EB"
      />
      <path
        d="M150.348 61.5202C154.229 60.5008 156.58 56.5132 155.213 52.7409C152.469 45.1711 148.594 38.0454 143.707 31.6115C137.352 23.2454 129.412 16.2128 120.34 10.9153C111.268 5.6178 101.241 2.15909 90.8316 0.736665C82.8266 -0.357247 74.7162 -0.230363 66.7753 1.10065C62.818 1.76394 60.5008 5.77114 61.5202 9.6519V9.6519C62.5396 13.5327 66.511 15.8094 70.4808 15.226C76.5618 14.3324 82.7506 14.2977 88.8643 15.1332C97.3827 16.2972 105.588 19.1277 113.013 23.4631C120.438 27.7984 126.935 33.5536 132.136 40.4002C135.868 45.314 138.879 50.7208 141.09 56.456C142.533 60.2 146.467 62.5396 150.348 61.5202V61.5202Z"
        fill="#B074FF"
      />
    </svg>
  )
}

export default SpinnerIcon
