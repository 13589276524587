import { Spinner } from './Spinner'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'

interface ModuleSpinnerProps {
  showLabels?: boolean
}

const ModuleSpinner: FC<ModuleSpinnerProps> = ({ showLabels }) => {
  const { t } = useTranslation('common')
  return (
    <div className="flex size-full flex-col items-center justify-center">
      {showLabels && <p className="mb-1 text-2xl font-medium">{t('loaderTitle')}</p>}
      {showLabels && <p className="mb-10 text-gray-700">{t('loaderSubTitle')}</p>}
      <Spinner className="size-40" />
    </div>
  )
}

export default ModuleSpinner
