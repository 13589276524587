"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrganizationSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.OrganizationSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Optional(typebox_1.Type.Integer()),
    parent_id: typebox_1.Type.Optional(typebox_1.Type.Integer()),
    name: typebox_1.Type.String({
        minLength: 1,
        maxLength: 100,
        errorMessage: 'Name must be between 1 and 100 characters.',
    }),
    created_at: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'date-time' })),
    updated_at: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'date-time' })),
});
