import { cva } from 'class-variance-authority'
import { forwardRef } from 'react'
import { IconButtonProps } from './types'
import clsx from 'clsx'
import { DEFAULT_BUTTON_THEME_CLASSNAMES, DEFAULT_BUTTON_VARIANT_CLASSNAMES } from '../constants'

export const iconButtonVariants = cva(['transition duration-300 ease-in-out rounded-md disabled:cursor-not-allowed'], {
  variants: {
    size: {
      thin: '',
      xs: 'p-1',
      sm: 'p-2',
      md: 'p-2.5',
      lg: 'p-3',
      xl: 'p-3.5',
    },
    variant: DEFAULT_BUTTON_VARIANT_CLASSNAMES,
    theme: DEFAULT_BUTTON_THEME_CLASSNAMES,
  },
  compoundVariants: [
    {
      variant: 'filled',
      theme: 'primary',
      className: 'text-white hover:text-white bg-purple-500 hover:bg-purple-600 border-purple-500',
    },
    {
      variant: 'filled',
      theme: 'danger',
      className: 'text-white hover:text-white bg-red-500 hover:bg-red-400',
    },
    {
      variant: 'filled',
      theme: 'secondary',
      className: 'text-black hover:text-black bg-gray-200 hover:bg-gray-300 disabled:bg-gray-200',
    },
  ],
})

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ className = '', size = 'md', variant = 'filled', type = 'button', theme = 'primary', ...props }, ref) => {
    const combinedClassNames = iconButtonVariants({
      size,
      variant,
      theme,
      class: className,
    })

    return <button ref={ref} type={type} className={clsx(combinedClassNames)} {...props} />
  },
)

IconButton.displayName = 'IconButton'

export default IconButton
