import { ErrorBoundary } from '@repo/ui/ErrorBoundary'
import ModuleSpinner from '@repo/ui/ModuleSpinner'
import { FC, Suspense, lazy } from 'react'
import LocalizationGuard from 'src/shared/guards/LocalizationGuard'
import RouterPermissionsGuard from 'src/shared/guards/RouterPermissionsGuard'

const PromoCodesList = lazy(() => import('src/modules/PromoCodesModule/PromoCodesList'))

const PromoCodesPage: FC = () => {
  return (
    <ErrorBoundary>
      <RouterPermissionsGuard permissions={{ StaffPositionManagement: ['read', 'edit'] }}>
        <LocalizationGuard namespace='promo-code-list'>
          <Suspense fallback={<ModuleSpinner />}>
            <PromoCodesList />
          </Suspense>
        </LocalizationGuard>
      </RouterPermissionsGuard>
    </ErrorBoundary>
  )
}

export default PromoCodesPage
