import { FC, Suspense } from 'react'
import { ErrorBoundary } from '@repo/ui/ErrorBoundary'
import ModuleSpinner from '@repo/ui/ModuleSpinner'
import LocalizationGuard from 'src/shared/guards/LocalizationGuard'
import MainCalendar from 'src/modules/CalendarModule'
import { BaseEvent } from '@repo/ui/Calendar'

// TODO: Dont need this
export const EVENTS = [
  {
    type: 'Урок',
    title: 'computerScience (two days)',
    start: new Date(2024, 7, 1, 0, 0),
    end: new Date(2024, 7, 2, 23, 59),
  },
  {
    type: 'Урок',
    title: 'computerScience (two days with time)',
    start: new Date(2024, 7, 1, 10),
    end: new Date(2024, 7, 2, 18),
  },
  {
    type: 'Урок',
    title: 'computerScience (full day) long naming',
    start: new Date(2024, 7, 1),
    end: new Date(2024, 7, 1),
  },
  {
    type: 'Урок',
    title: 'computerScience (full day)',
    start: new Date(2024, 7, 1),
    end: new Date(2024, 7, 1),
  },
  {
    type: 'Урок',
    title: 'computerScience 1 (10:00 - 12:00)',
    start: new Date(2024, 7, 1, 10),
    end: new Date(2024, 7, 1, 12),
  },
  {
    type: 'Урок',
    title: 'computerScience 2 (12:00 - 17:00)',
    start: new Date(2024, 7, 1, 10),
    end: new Date(2024, 7, 1, 17),
  },
  {
    type: 'Урок',
    title: 'mathematics (10:30 - 15:15)',
    start: new Date(2024, 7, 3, 10, 30),
    end: new Date(2024, 7, 3, 15, 15),
  },
]
export interface ExtendedEvent extends BaseEvent {
  type: string
}

const CalendarPage: FC = () => {
  return (
    <ErrorBoundary>
      <LocalizationGuard namespace='calendar'>
        <Suspense fallback={<ModuleSpinner />}>
          <MainCalendar />
        </Suspense>
      </LocalizationGuard>
    </ErrorBoundary>
  )
}

export default CalendarPage
