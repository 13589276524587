"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchoolYearFindResponseSchema = exports.SchoolYearFindParamsSchema = exports.SchoolYearFindOrderSchema = exports.SchoolYearFindFilterParamsSchema = exports.SchoolYearParamsSchema = exports.SchoolYearSchemaResponse = exports.SchoolYearCreateUpdateSchema = exports.SchoolYearSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const responses_1 = require("../../responses");
const types_1 = require("./types");
const baseEntity_1 = require("../baseEntity");
exports.SchoolYearSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    status: typebox_1.Type.Enum(types_1.SchoolYearStatus),
    start: typebox_1.Type.String({ format: 'date-time' }),
    end: typebox_1.Type.String({ format: 'date-time' }),
}, { additionalProperties: false });
exports.SchoolYearCreateUpdateSchema = typebox_1.Type.Omit(exports.SchoolYearSchema, ['id']);
exports.SchoolYearSchemaResponse = typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.SchoolYearSchema]);
exports.SchoolYearParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
});
exports.SchoolYearFindFilterParamsSchema = typebox_1.Type.Object({
    status: typebox_1.Type.Optional(typebox_1.Type.Enum(types_1.SchoolYearStatus)),
});
exports.SchoolYearFindOrderSchema = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.SchoolYearSchemaResponse, [
    'status',
    'start',
    'created_at',
]));
exports.SchoolYearFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.SchoolYearFindFilterParamsSchema,
    order: exports.SchoolYearFindOrderSchema,
});
exports.SchoolYearFindResponseSchema = (0, responses_1.PaginatedList)(exports.SchoolYearSchemaResponse);
