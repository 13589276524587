import ROUTES from '../constants'
import { lazy, Suspense } from 'react'
import ModuleSpinner from '@repo/ui/ModuleSpinner'

const NotFoundPage = lazy(() => import('src/pages/ServicePages/NotFound'))
const ForbiddenPage = lazy(() => import('src/pages/ServicePages/Forbidden'))

export default [
  {
    path: ROUTES.FORBIDDEN,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <ForbiddenPage />
      </Suspense>
    ),
  },
  {
    path: '*',
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <NotFoundPage />
      </Suspense>
    ),
  },
]
