import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import { container } from 'tsyringe'
import { DEFAULT_LANGUAGE } from './constants'
import { indexedDBConfigFactory, IndexedDBConfigService } from '../services/IndexedDB/config'

const bootstrap = () => {
  container.register(IndexedDBConfigService, {
    useFactory: indexedDBConfigFactory(
      import.meta.env.VITE_LOCALIZATION_DB_NAME,
      1,
      import.meta.env.VITE_LOCALIZATION_DB_STORE_NAME,
    ),
  })

  intervalPlural.setOptions({
    // these are the defaults
    intervalSeparator: ';',
    intervalRegex: /\((\S*)\).*?\[((.|\n)*)\]/, // pre 3.0 /\((\S*)\).*{((.|\n)*)}/,
    intervalSuffix: '_interval',
  })

  i18n
    .use(initReactI18next)
    .use(intervalPlural)
    .init({
      resources: {},
      lng: DEFAULT_LANGUAGE,
      keySeparator: '.',
      interpolation: {
        escapeValue: false,
      },
      debug: false,
      react: {
        bindI18nStore: 'added',
        bindI18n: 'languageChanged',
        useSuspense: false,
      },
    })

  return { i18n }
}

export default bootstrap
