"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserGroupAttachDetachSchema = exports.UserGroupCoursesFindResponseSchema = exports.UserGroupFindResponseSchema = exports.UserGroupFindParamsSchema = exports.UserGroupFindOrderSchema = exports.UserGroupFindFilterParamsSchema = exports.UserGroupParamsSchema = exports.UserGroupSchemaResponse = exports.UserGroupCreateUpdateSchema = exports.UserGroupSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const responses_1 = require("../../responses");
const baseEntity_1 = require("../baseEntity");
exports.UserGroupSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: typebox_1.Type.String({ minLength: 1, maxLength: 45 }),
}, { additionalProperties: false });
exports.UserGroupCreateUpdateSchema = typebox_1.Type.Omit(exports.UserGroupSchema, ['id']);
exports.UserGroupSchemaResponse = typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.UserGroupSchema]);
exports.UserGroupParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
});
exports.UserGroupFindFilterParamsSchema = typebox_1.Type.Object({
    name: typebox_1.Type.Optional(typebox_1.Type.String()),
});
exports.UserGroupFindOrderSchema = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.UserGroupSchemaResponse, [
    'name',
    'created_at',
]));
exports.UserGroupFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.UserGroupFindFilterParamsSchema,
    order: exports.UserGroupFindOrderSchema,
});
exports.UserGroupFindResponseSchema = (0, responses_1.PaginatedList)(exports.UserGroupSchemaResponse);
exports.UserGroupCoursesFindResponseSchema = typebox_1.Type.Array(exports.UserGroupSchemaResponse);
exports.UserGroupAttachDetachSchema = typebox_1.Type.Object({
    userIds: typebox_1.Type.Array(typebox_1.Type.Integer()),
    userGroupIds: typebox_1.Type.Array(typebox_1.Type.Integer()),
});
