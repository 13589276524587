export const DEFAULT_DEBOUNCE_DELAY = 1000
export const SELECTOR_PORTALS_ELEMENT_ID = 'selector-portals'
export const MODAL_PORTALS_ELEMENT_ID = 'modals'

export const FORMATTERS = {
  DATE: {
    YEAR: 'yyyy',
    DATE: 'dd.MM.yyyy',
    DATE_TIME: 'dd.MM.yyyy HH:mm',
    ISO: "yyyy-MM-dd'T'HH:mm:ss",
  },
}

export const LITERALS = {
  DASH: '-',
  QUESTION_MARK: '?',
  SLASH: '/',
}
