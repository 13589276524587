import { FC } from 'react'
import Calendar, { BaseEvent } from '@repo/ui/Calendar'

export const EVENTS = [
  {
    type: 'Урок',
    title: 'computerScience (two days)',
    start: new Date(2024, 7, 1, 0, 0),
    end: new Date(2024, 7, 2, 23, 59),
  },
  {
    type: 'Урок',
    title: 'computerScience (two days with time)',
    start: new Date(2024, 7, 1, 10),
    end: new Date(2024, 7, 2, 18),
  },
  {
    type: 'Урок',
    title: 'computerScience (full day) long naming',
    start: new Date(2024, 7, 1),
    end: new Date(2024, 7, 1),
  },
  {
    type: 'Урок',
    title: 'computerScience (full day)',
    start: new Date(2024, 7, 1),
    end: new Date(2024, 7, 1),
  },
  {
    type: 'Урок',
    title: 'computerScience 1 (10:00 - 12:00)',
    start: new Date(2024, 7, 1, 10),
    end: new Date(2024, 7, 1, 12),
  },
  {
    type: 'Урок',
    title: 'computerScience 2 (12:00 - 17:00)',
    start: new Date(2024, 7, 1, 10),
    end: new Date(2024, 7, 1, 17),
  },
  {
    type: 'Урок',
    title: 'mathematics (10:30 - 15:15)',
    start: new Date(2024, 7, 3, 10, 30),
    end: new Date(2024, 7, 3, 15, 15),
  },
]
export interface ExtendedEvent extends BaseEvent {
  type: string
}

export interface PopoverContentComponentProps<T> {
  event: T
  onClose: () => void
  onEdit: () => void
  onDelete: () => void
}

export const PopoverContentComponent: FC<PopoverContentComponentProps<ExtendedEvent>> = ({ event }) => {
  return (
    <div className='space-y-2 text-sm'>
      <div className='grid grid-cols-2 gap-x-4'>
        <span className='font-medium text-gray-500'>Тип події:</span>
        <span>{event.type}</span>
      </div>
      <div className='grid grid-cols-2 gap-x-4'>
        <span className='font-medium text-gray-500'>Дата:</span>
        <span>30.09.2024</span>
      </div>
      <div className='grid grid-cols-2 gap-x-4'>
        <span className='font-medium text-gray-500'>Час:</span>
        <span>10:30 - 12:00</span>
      </div>
    </div>
  )
}

const MainCalendar = () => {
  return <Calendar<ExtendedEvent> events={EVENTS} PopoverContentComponent={PopoverContentComponent} />
}
export default MainCalendar
