import { MutableRefObject, ComponentType } from 'react'
import Popper from '../../Popper'
import { IconButton } from '../../Buttons'
import { PenIcon, RecycleBinIcon, CloseIcon } from '../../Icons'
import { Event as BaseEvent } from 'react-big-calendar'

interface EventDetailsPopoverProps<T> {
  sourceRef: MutableRefObject<HTMLElement | SVGSVGElement | null>
  isPopperOpen: boolean
  closePopper: () => void
  selectedEvent: T
  PopoverContentComponent: ComponentType<{
    event: T
    onClose: () => void
    onEdit: () => void
    onDelete: () => void
  }>
}

const EventDetailsPopover = <T extends BaseEvent>({
  sourceRef,
  isPopperOpen,
  closePopper,
  selectedEvent,
  PopoverContentComponent,
}: EventDetailsPopoverProps<T>) => {
  return (
    <Popper sourceRef={sourceRef} isOpen={isPopperOpen} onClose={closePopper} placement="bottom" inPortal={true}>
      {selectedEvent && (
        <div className="w-[400px] rounded-lg border border-gray-200 bg-white p-4 shadow-lg">
          <div className="mb-4 flex items-center justify-between">
            <h3 className="text-lg font-semibold">{selectedEvent.title}</h3>
            <div className="flex space-x-4">
              <IconButton onClick={() => console.log('onEdit')} variant="text" size="thin">
                <PenIcon className="size-4 stroke-gray-900" />
              </IconButton>
              <IconButton onClick={() => console.log('onDelete')} variant="text" size="thin">
                <RecycleBinIcon className="size-4 stroke-red-800" />
              </IconButton>
              <IconButton onClick={closePopper} variant="text" size="thin">
                <CloseIcon width={16} height={16} />
              </IconButton>
            </div>
          </div>
          <hr className="mb-4 border-gray-200" />
          <PopoverContentComponent
            event={selectedEvent}
            onClose={closePopper}
            onEdit={() => console.log('onEdit')}
            onDelete={() => console.log('onDelete')}
          />
        </div>
      )}
    </Popper>
  )
}

export default EventDetailsPopover
