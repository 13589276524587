import { createContext, PropsWithChildren, useContext } from 'react'
import { ChevronLeftIcon } from '@heroicons/react/16/solid'
import { useDisclosure } from '../../hooks/useDisclosure'
import { clsx } from 'clsx'

const PrivateLayout = ({ children }: PropsWithChildren) => {
  return <div className="flex max-h-screen min-h-screen w-full gap-4 bg-slate-50">{children}</div>
}

const AsideContext = createContext(true)
export const useAsideContext = () => useContext(AsideContext)

PrivateLayout.Aside = function Aside({ children }: PropsWithChildren) {
  const { isOpen, toggle } = useDisclosure(true)

  return (
    <div
      className={clsx(
        {
          'w-56 px-4': isOpen,
          'w-20 px-1': !isOpen,
        },
        'relative bg-white py-4 shadow-sm duration-300 ease-in-out will-change-contents',
      )}
      onAnimationEnd={toggle}
    >
      <AsideContext.Provider value={isOpen}>{children}</AsideContext.Provider>
      <button
        className="absolute -right-2 top-1/2 h-10 w-4 -translate-y-1/2 rounded-full border border-gray-400 bg-white"
        onClick={toggle}
      >
        <ChevronLeftIcon className={clsx(isOpen ? 'rotate-0' : 'rotate-180')} />
      </button>
    </div>
  )
}

PrivateLayout.Content = function Content({ children }: PropsWithChildren) {
  return (
    <div className="grow overflow-auto">
      <div className="my-4 mr-4 grow rounded-lg bg-white">{children}</div>
    </div>
  )
}

export default PrivateLayout
