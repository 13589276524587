import { MenuItemType } from '../Menu/MenuTypes'
import { MenuItemCollapse } from './components/MenuItemCollapse'
import { MenuItemLink } from './components/MenuListItemLink'
import useCheckPermissions from 'src/shared/hooks/useCheckPermissions.ts'

interface MenuListItemProps {
  item: MenuItemType
}

const MenuItem = ({ item }: MenuListItemProps) => {
  const canSee = useCheckPermissions(item.permissions)
  if (!canSee) return null

  return <MenuItemLink key={item.path} path={item.path} label={item.label} leftIcon={item.icon} />
}

export const MenuListItem = ({ item }: MenuListItemProps) => {
  const hasChildren = !!item.child

  if (!hasChildren) {
    return <MenuItem item={item} />
  }

  return (
    <MenuItemCollapse
      key={`menu-item-collapse-${item.path}`}
      leftIcon={item.icon}
      label={item.label}
      permissions={item.permissions}
    >
      {item.child?.map((c) => <MenuListItem key={c.path} item={c} />)}
    </MenuItemCollapse>
  )
}
