import { ActionType, SubjectType } from '@repo/contracts'
import { FC, PropsWithChildren } from 'react'
import useCheckPermissions from 'src/shared/hooks/useCheckPermissions.ts'
import { Navigate } from 'react-router-dom'
import ROUTES from 'src/app/router/constants'

interface PermissionsGuardProps extends PropsWithChildren {
  permissions?: Partial<Record<SubjectType, ActionType[]>>
}

const RouterPermissionsGuard: FC<PermissionsGuardProps> = ({ permissions, children }) => {
  const can = useCheckPermissions(permissions)
  if (!can) return <Navigate to={ROUTES.FORBIDDEN} replace />

  return children
}

export default RouterPermissionsGuard
