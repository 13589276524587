import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const TimeGutterHeader: FC = () => {
  const { t } = useTranslation('calendar')

  return <span>{t('allDay').toUpperCase()}</span>
}

export default TimeGutterHeader
