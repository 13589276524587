"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopicParamsSchema = exports.TopicSchemaResponse = exports.TopicUpdateSchema = exports.TopicCreateFormSchema = exports.TopicCreateSchema = exports.TopicSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const types_1 = require("./types");
exports.TopicSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: typebox_1.Type.String({ minLength: 2, maxLength: 500 }),
    course_version_id: typebox_1.Type.Integer(),
    semester: typebox_1.Type.Enum(types_1.SEMESTER),
    required: typebox_1.Type.Boolean({ default: false }),
    order: typebox_1.Type.Integer({ minimum: 1 }),
}, { additionalProperties: false });
exports.TopicCreateSchema = typebox_1.Type.Omit(exports.TopicSchema, ['id']);
exports.TopicCreateFormSchema = typebox_1.Type.Pick(exports.TopicSchema, ['name']);
exports.TopicUpdateSchema = typebox_1.Type.Omit(exports.TopicSchema, ['id', 'course_version_id']);
exports.TopicSchemaResponse = exports.TopicSchema;
exports.TopicParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
});
