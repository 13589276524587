import { useAsideContext } from '@repo/ui/PrivateLayout'
import clsx from 'clsx'
import { ReactNode } from 'react'
import ChevronUpIcon from '@heroicons/react/24/outline/ChevronUpIcon'

interface MenuItemButtonProps {
  onClick?: () => void
  path?: string
  children: ReactNode
  leftIcon?: ReactNode
  className?: string
  isMenuOpen?: boolean
}

export const MenuItemButton = ({ className, onClick, children, leftIcon, isMenuOpen }: MenuItemButtonProps) => {
  const isAsideOpen = useAsideContext()

  return (
    <li className={clsx('list-none', className)}>
      <button
        className='flex w-full items-center gap-1.5 overflow-hidden px-2.5 py-2 hover:bg-primary-hover/30 focus:bg-primary-hover/30'
        onClick={onClick}
      >
        {leftIcon && <div className='size-5 shrink-0'>{leftIcon}</div>}
        {isAsideOpen && <span className={clsx('font-medium')}>{children}</span>}
        {isAsideOpen && (
          <span
            className={clsx(
              {
                'rotate-180': !isMenuOpen,
              },
              'ml-auto size-5 shrink-0',
            )}
          >
            <ChevronUpIcon />
          </span>
        )}
      </button>
    </li>
  )
}
