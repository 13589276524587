"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuestionTagsAttachDetachToQuestionSchema = exports.QuestionsTagsSchema = exports.QuestionTagDeletePayload = exports.QuestionTagListFindResponseSchema = exports.QuestionTagFindParamsSchema = exports.QuestionTagFindOrderParams = exports.QuestionTagFindFilterParamsSchema = exports.QuestionTagResponseSchema = exports.QuestionTagCreateUpdateResponseSchema = exports.QuestionTagParamsSchema = exports.QuestionTagCreateUpdateSchema = exports.QuestionTagSchema = exports.QuestionTagBaseSchema = exports.QuestionTagName = void 0;
const typebox_1 = require("@sinclair/typebox");
const baseEntity_1 = require("../baseEntity");
const responses_1 = require("../../responses");
exports.QuestionTagName = typebox_1.Type.String({ minLength: 1, maxLength: 500 });
exports.QuestionTagBaseSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: exports.QuestionTagName,
}, { additionalProperties: false });
exports.QuestionTagSchema = typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.QuestionTagBaseSchema]);
exports.QuestionTagCreateUpdateSchema = typebox_1.Type.Pick(exports.QuestionTagSchema, ['name']);
exports.QuestionTagParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
});
exports.QuestionTagCreateUpdateResponseSchema = typebox_1.Type.Pick(exports.QuestionTagSchema, ['id']);
exports.QuestionTagResponseSchema = typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.QuestionTagSchema], {
    additionalProperties: false,
});
exports.QuestionTagFindFilterParamsSchema = typebox_1.Type.Object({
    name: typebox_1.Type.Optional(typebox_1.Type.String()),
});
exports.QuestionTagFindOrderParams = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.QuestionTagResponseSchema, [
    'name',
]));
exports.QuestionTagFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.QuestionTagFindFilterParamsSchema,
    order: exports.QuestionTagFindOrderParams,
});
exports.QuestionTagListFindResponseSchema = (0, responses_1.PaginatedList)(exports.QuestionTagSchema);
exports.QuestionTagDeletePayload = typebox_1.Type.Object({
    ids: typebox_1.Type.Array(typebox_1.Type.Integer()),
});
exports.QuestionsTagsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    question_id: typebox_1.Type.Integer(),
    question_tag_id: typebox_1.Type.Integer(),
});
exports.QuestionTagsAttachDetachToQuestionSchema = typebox_1.Type.Object({
    ids: typebox_1.Type.Array(typebox_1.Type.Integer()),
});
