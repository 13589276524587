import { useMemo } from 'react'
import { ToolbarProps, View, Views } from 'react-big-calendar'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import Select from '../../Select'
import { useTranslation } from 'react-i18next'
import { getOptionsFromEnum } from '../../utils'
import { CALENDAR_VIEW } from '../constants'
import { format, isToday } from 'date-fns'
import clsx from 'clsx'
import { Event as BaseEvent } from 'react-big-calendar'

interface TopToolbarProps<T extends BaseEvent> extends ToolbarProps<T> {}

type PeriodOptionsType = {
  label: string
  value: View
}

const calendarViewLocalizationPrefix = 'Calendar_View'

const TopToolbar = <T extends BaseEvent>({ label, view, onNavigate, onView, date }: TopToolbarProps<T>) => {
  const { t } = useTranslation('calendar')

  const dayNumber = format(date, 'd')
  const localizedDay = t(`weekdays_${date.getDay()}`).toUpperCase()
  const isCurrentDay = isToday(date)

  const calendarViewOptions = useMemo(() => getOptionsFromEnum(CALENDAR_VIEW, t, calendarViewLocalizationPrefix), [t])

  return (
    <div className="mb-5">
      <div className="flex items-center">
        <span className="mr-1 min-w-52 font-semibold">{label}</span>
        <div className="flex items-center gap-2">
          <button
            className="flex items-center justify-center rounded-lg bg-gray-50 p-2 hover:bg-gray-100"
            type="button"
            onClick={() => onNavigate('PREV')}
          >
            <ChevronLeftIcon className="size-5 cursor-pointer fill-blue-600" />
          </button>
          <button
            className="flex items-center justify-center rounded-lg bg-gray-50 p-2 hover:bg-gray-100"
            type="button"
            onClick={() => onNavigate('NEXT')}
          >
            <ChevronRightIcon className="size-5 cursor-pointer fill-blue-600" />
          </button>
        </div>
        <div className="ml-4">
          <Select<PeriodOptionsType, false>
            options={calendarViewOptions}
            value={calendarViewOptions.find((item) => item.value === view)}
            onChange={(option) => {
              if (option) {
                onView(option.value)
              }
            }}
          />
        </div>
      </div>
      {view === Views.DAY && (
        <div className="ml-20 mt-6">
          <div className="h-full border-b border-b-gray-200">
            <div className="relative w-24">
              <div className="flex flex-col items-center pb-1">
                <div className={clsx('text-xl font-semibold', isCurrentDay ? 'text-blue-500' : 'text-blue-950')}>
                  {dayNumber}
                </div>
                <div className={clsx(isCurrentDay ? 'text-blue-500' : 'text-gray-400')}>{localizedDay}</div>
              </div>
              {isCurrentDay && <div className="absolute bottom-0 left-0 z-10 h-0.5 w-full bg-blue-500" />}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default TopToolbar
