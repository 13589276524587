import { FindParamsArguments, StudentFindParamsType } from '@repo/contracts'
import { QueryKey } from '@tanstack/react-query'

export const USERS_QUERY_KEYS = {
  root: ['users'] as QueryKey,
  me: function () {
    return this.root.concat('me')
  },
  findAll: function () {
    return this.root.concat('find')
  },
  find: function (params: FindParamsArguments) {
    return this.root.concat('find', params)
  },
}

export const STUDENTS_QUERY_KEYS = {
  root: ['students'] as QueryKey,
  findAll: function () {
    return this.root.concat('find')
  },
  find: function (params: StudentFindParamsType) {
    return this.root.concat('find', params)
  },
}
