"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangePasswordFormSchema = exports.ChangePasswordParamsSchema = exports.PasswordResetParamsSchema = exports.MicrosoftUrlParamsSchema = exports.ConfirmResponseSchema = exports.LoginResponseSchema = exports.PairToken = exports.RefreshTokenParamsSchema = exports.SSORegistrationParamsSchema = exports.RegistrationResponseSchema = exports.RegistrationParamsSchema = exports.UserCombinedLoginSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const response_1 = require("../response");
const user_1 = require("../user");
const common_1 = require("../../common");
const utils_1 = require("../../utils");
exports.UserCombinedLoginSchema = typebox_1.Type.Object({
    loginMethod: typebox_1.Type.String({
        enum: ['email', 'phone'],
    }),
    phone_number: typebox_1.Type.Optional(common_1.PhoneNumberSchema),
    code: typebox_1.Type.Optional(typebox_1.Type.String({
        minLength: 1,
        maxLength: 6,
    })),
    password: typebox_1.Type.Optional(common_1.PasswordSchema),
    email: typebox_1.Type.Optional(common_1.EmailSchema),
}, {
    additionalProperties: false,
    required: ['loginMethod'],
    anyOf: [
        {
            required: ['email', 'password'],
        },
        {
            required: ['phone_number'],
        },
    ],
});
exports.RegistrationParamsSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(user_1.UserBaseSchema, ['code', 'is_verified', 'contact_priority']),
    typebox_1.Type.Object({
        phone_number: common_1.PhoneNumberSchema,
        email: common_1.EmailSchema,
    }),
    typebox_1.Type.Object({
        confirm_password: typebox_1.Type.Unsafe({ type: 'string', const: { $data: '1/password' } }),
    }),
]);
exports.RegistrationResponseSchema = typebox_1.Type.Omit((0, utils_1.MakeOptional)(exports.RegistrationParamsSchema, ['phone_number']), [
    'password',
    'confirm_password',
]);
exports.SSORegistrationParamsSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(user_1.UserBaseSchema, ['password', 'code']),
    typebox_1.Type.Object({
        email: common_1.EmailSchema,
    }),
]);
exports.RefreshTokenParamsSchema = typebox_1.Type.Object({
    refreshToken: typebox_1.Type.String({
        minLength: 1,
    }),
});
exports.PairToken = typebox_1.Type.Object({
    accessToken: typebox_1.Type.String(),
    refreshToken: typebox_1.Type.String(),
});
exports.LoginResponseSchema = typebox_1.Type.Union([response_1.MessageResponse, exports.PairToken]);
exports.ConfirmResponseSchema = (0, response_1.BaseResponse)(user_1.UserSchemaResponse);
exports.MicrosoftUrlParamsSchema = typebox_1.Type.Object({ code: typebox_1.Type.String() });
exports.PasswordResetParamsSchema = typebox_1.Type.Object({
    email: common_1.EmailSchema,
});
exports.ChangePasswordParamsSchema = typebox_1.Type.Object({
    token: typebox_1.Type.String({ errorMessage: 'Token is required.' }),
    password: common_1.PasswordSchema,
});
exports.ChangePasswordFormSchema = typebox_1.Type.Object({
    password: common_1.PasswordSchema,
    secondPassword: typebox_1.Type.Unsafe({ type: 'string', const: { $data: '1/password' } }),
});
