import { FC } from 'react'
import { format, isToday } from 'date-fns'
import { HeaderProps } from 'react-big-calendar'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

const WeekDateHeader: FC<HeaderProps> = ({ date }) => {
  const { t } = useTranslation('calendar')
  const dayNumber = format(date, 'd')
  const localizedDay = t(`weekdays_${date.getDay()}`).toUpperCase()
  const isCurrentDay = isToday(date)

  return (
    <div className="flex h-full flex-col">
      <div className={clsx('text-xl font-semibold', isCurrentDay ? 'text-blue-500' : 'text-blue-950')}>{dayNumber}</div>
      <div className={clsx(isCurrentDay && 'text-blue-500')}>{localizedDay}</div>
    </div>
  )
}

export default WeekDateHeader
