import { SVGProps } from 'react'

const Ukraine = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="20" rx="2" fill="white" />
      <mask
        id="mask0_3340_61740"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="28"
        height="20"
      >
        <rect width="28" height="20" rx="2" fill="white" />
      </mask>
      <g mask="url(#mask0_3340_61740)">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 10.6667H28V0H0V10.6667Z" fill="#156DD1" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 20H28V10.6666H0V20Z" fill="#FFD948" />
      </g>
    </svg>
  )
}

export default Ukraine
