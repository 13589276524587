import { MenuItemLink } from 'src/components/MenuListItem/components/MenuListItemLink'
import BellAlertIcon from '@heroicons/react/24/outline/BellAlertIcon'
import { useAsideContext } from '@repo/ui/PrivateLayout'
import clsx from 'clsx'
import { MAX_NOTIFICATIONS_BADGE_COUNT } from 'src/components/Layouts/PrivateLayout/constants'

export const NotificationsMenuItemButton = () => {
  const isAsideOpen = useAsideContext()
  const value = 188 // ToDo: retrieve correct notifications amount

  return (
    <div
      className={clsx(
        {
          'justify-center': !isAsideOpen,
          'justify-between': isAsideOpen,
        },
        'flex items-center',
      )}
    >
      <MenuItemLink
        path={'/notifications'}
        label={'notifications'}
        leftIcon={<BellAlertIcon />}
        rightIcon={
          isAsideOpen && (
            <span className='ml-auto flex size-7 items-center justify-center rounded-full bg-purple-400 text-xs font-semibold leading-none text-white'>
              {value <= MAX_NOTIFICATIONS_BADGE_COUNT ? value : `${MAX_NOTIFICATIONS_BADGE_COUNT}+`}
            </span>
          )
        }
      />
    </div>
  )
}
