import { useAsideContext } from '@repo/ui/PrivateLayout'
import LanguagesSelect from 'src/components/LanguagesSelect'
import { CSSObjectWithLabel, StylesConfig } from 'react-select'
import { LanguageOption } from 'src/components/LanguagesSelect/constants.tsx'

const selectCustomStyles: StylesConfig<LanguageOption, false> = {
  input: (provided: CSSObjectWithLabel) => ({
    ...provided,
    minWidth: '43px',
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    paddingLeft: '8px',
  }),
  option: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'center',
  }),
}

const SideBarLanguageSelect = () => {
  const isAsideOpen = useAsideContext()

  return (
    <LanguagesSelect
      selectContainerClassName='w-48'
      selectCustomStyles={isAsideOpen ? {} : selectCustomStyles}
      useSimplifiedLanguageOptions={!isAsideOpen}
    />
  )
}

export default SideBarLanguageSelect
