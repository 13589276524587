import ROUTES from '../constants'
import { lazy, Suspense } from 'react'
import ModuleSpinner from '@repo/ui/ModuleSpinner'

const CourseListPage = lazy(() => import('src/pages/CoursePages/CourseListPage'))
const CreateCoursePage = lazy(() => import('src/pages/CoursePages/CreateCoursePage'))

export default [
  {
    path: ROUTES.COURSES.LIST,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <CourseListPage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.COURSES.CREATE,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <CreateCoursePage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.COURSES.EDIT,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <CreateCoursePage />
      </Suspense>
    ),
  },
]
