import axios from 'axios'
import { API_URL, LOCALIZATION_TOKEN, LOCALIZATION_URL } from 'src/shared/constants/api'

export const apiClient = axios.create({
  baseURL: API_URL,
})

export const localizationClient = axios.create({
  baseURL: LOCALIZATION_URL,
  headers: {
    Authorization: `Bearer ${LOCALIZATION_TOKEN}`,
  },
})
