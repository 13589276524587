"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LessonElementType = exports.LessonDoneCriteria = exports.ViewType = void 0;
var ViewType;
(function (ViewType) {
    ViewType["InfiniteScroll"] = "InfiniteScroll";
    ViewType["Pages"] = "Pages";
})(ViewType || (exports.ViewType = ViewType = {}));
var LessonDoneCriteria;
(function (LessonDoneCriteria) {
    LessonDoneCriteria["MaterialsExaminedUpToDate"] = "MaterialsExaminedUpToDate";
    LessonDoneCriteria["RequiredElementsDoneUpToDate"] = "RequiredElementsDoneUpToDate";
    LessonDoneCriteria["UploadedFile"] = "UploadedFile";
})(LessonDoneCriteria || (exports.LessonDoneCriteria = LessonDoneCriteria = {}));
var LessonElementType;
(function (LessonElementType) {
    LessonElementType["Video"] = "Video";
    LessonElementType["Page"] = "Page";
    LessonElementType["Homework"] = "Homework";
})(LessonElementType || (exports.LessonElementType = LessonElementType = {}));
