import { useTranslation } from 'react-i18next'
import Select from '@repo/ui/Select'
import {
  LanguageOption,
  languageOptions,
  simplifiedLanguageOptions,
} from 'src/components/LanguagesSelect/constants.tsx'
import { FC, useState, useEffect } from 'react'
import { SingleValue } from 'react-select'
import { useQueryClient } from '@tanstack/react-query'
import { LOCALIZATION_QUERY_KEYS } from 'src/shared/constants/query.ts'
import clsx from 'clsx'
import { StylesConfig } from 'react-select'
import { useService } from 'src/shared/hooks/di'
import { LocalizationService } from 'src/shared/services/Localization'

interface LanguagesSelectProps {
  className?: string
  selectContainerClassName?: string
  selectCustomStyles?: StylesConfig<LanguageOption, false>
  useSimplifiedLanguageOptions?: boolean
}

const LanguagesSelect: FC<LanguagesSelectProps> = ({
  className,
  selectContainerClassName,
  selectCustomStyles,
  useSimplifiedLanguageOptions,
}) => {
  const queryClient = useQueryClient()
  const { i18n } = useTranslation()
  const { updateLocale, locale } = useService(LocalizationService)
  const [languageValue, setLanguageValue] = useState<SingleValue<LanguageOption>>(() => {
    const langOptions = useSimplifiedLanguageOptions ? simplifiedLanguageOptions : languageOptions

    return langOptions.find(({ value }) => value === i18n.language) ?? langOptions[0]
  })

  useEffect(() => {
    const langOptions = useSimplifiedLanguageOptions ? simplifiedLanguageOptions : languageOptions
    const newLanguageValue = langOptions.find(({ value }) => value === i18n.language) ?? langOptions[0]

    setLanguageValue(newLanguageValue)
  }, [useSimplifiedLanguageOptions, i18n.language])

  const onLanguageChange = async (newValue: SingleValue<LanguageOption>) => {
    if (newValue) {
      updateLocale(newValue.value)
      await queryClient.invalidateQueries({
        queryKey: LOCALIZATION_QUERY_KEYS.namespace(locale),
        type: 'active',
      })
    }
    setLanguageValue(newValue)
  }

  return (
    <div className={clsx('flex shrink-0 items-center', className)}>
      <Select<LanguageOption, false>
        value={languageValue}
        onChange={onLanguageChange}
        options={useSimplifiedLanguageOptions ? simplifiedLanguageOptions : languageOptions}
        menuPlacement={useSimplifiedLanguageOptions ? 'top' : 'auto'}
        customStyles={selectCustomStyles}
        containerClassName={selectContainerClassName ?? ''}
      />
    </div>
  )
}

export default LanguagesSelect
