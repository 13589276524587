import { createBrowserRouter } from 'react-router-dom'
import { PrivateLayout } from 'src/components/Layouts/PrivateLayout'
import ROUTES from './constants'
import CourseRoutes from './routes/CourseRoutes'
import StudyPlanRoutes from './routes/StudyPlanRoutes'
import AgeGroupRoutes from './routes/AgeGroupRoutes'
import UserRoutes from './routes/UserRoutes'
import DictionaryRoutes from './routes/DictionaryRoutes'
import RoleRoutes from './routes/RoleRoutes'
import PositionRoutes from './routes/PositionRoutes'
import ServiceRoutes from './routes/ServiceRoutes'
import AuthRoutes from './routes/AuthRoutes'
import HomePage from 'src/pages/HomePage'
import CalendarPage from 'src/pages/CalendarPage'
import LessonsRoutes from './routes/LessonsRoutes'
import PromoCodeRoutes from './routes/PromoCodeRoutes.tsx'
import TestsRoutes from './routes/TestsRoutes.tsx'

const privateRoutes = [
  {
    index: true,
    path: ROUTES.HOME,
    element: <HomePage />,
  },
  {
    path: ROUTES.CALENDAR,
    element: <CalendarPage />,
  },
  ...CourseRoutes,
  ...StudyPlanRoutes,
  ...AgeGroupRoutes,
  ...UserRoutes,
  ...DictionaryRoutes,
  ...RoleRoutes,
  ...PositionRoutes,
  ...ServiceRoutes,
  ...LessonsRoutes,
  ...PromoCodeRoutes,
  ...TestsRoutes,
]

export const ROUTER = createBrowserRouter([
  ...AuthRoutes,
  {
    path: '/',
    element: <PrivateLayout />,
    children: privateRoutes,
  },
])
