import { createContext, FC, PropsWithChildren, useCallback, useContext } from 'react'
import { AnyAbility } from '@casl/ability'
import { useService } from 'src/shared/hooks/di'
import { ActionType, SubjectType } from '@repo/contracts'
import { AbilityService } from '../services/AbilityService'

export const AbilityContext = createContext<AnyAbility>(undefined!)

export const AbilityProvider: FC<PropsWithChildren> = ({ children }) => {
  const { abilities } = useService(AbilityService)

  if (!abilities) {
    throw new Error('No abilities defined!')
  }

  return <AbilityContext.Provider value={abilities}>{children}</AbilityContext.Provider>
}

export const useAbilityContext = () => {
  const ability = useContext(AbilityContext)

  const can = useCallback(
    (action: ActionType, subject: SubjectType) => {
      return ability.can(action, subject)
    },
    [ability],
  )

  return {
    ability,
    can,
  }
}
