export const SimpleLogoIcon = () => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.0273 20.4263L19.4167 16.9629L17.0001 11.6079L13.0273 20.4263Z" fill="#C8A0FD" />
      <path
        d="M20.5814 19.5439L11.1169 24.666H8.00767L15.7141 7.58834H18.2922L28.294 29.7011C31.7946 26.5887 34 22.0506 34 17C34 7.61319 26.3899 0 17 0C7.61319 0 0 7.61009 0 17C0 26.3868 7.61009 34 17 34C20.2925 34 23.3676 33.0588 25.9706 31.4374L20.5814 19.5439Z"
        fill="#C8A0FD"
      />
    </svg>
  )
}
