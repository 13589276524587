import { useAbility } from '@casl/react'
import { AbilityContext } from 'src/shared/context/AbilityProvider.tsx'
import { checkPermissions } from 'src/shared/utils/permissions.ts'
import { ActionType, SubjectType } from '@repo/contracts'
import { useMemo } from 'react'

const useCheckPermissions = (permissions?: Partial<Record<SubjectType, ActionType[]>>) => {
  const ability = useAbility(AbilityContext)

  return useMemo(() => checkPermissions(ability, permissions), [ability, permissions])
}

export default useCheckPermissions
