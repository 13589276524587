import { cva } from 'class-variance-authority'
import { forwardRef } from 'react'
import { ButtonProps } from './types'
import clsx from 'clsx'
import { BaseButtonClassSettings } from '../types'
import { DEFAULT_BUTTON_THEME_CLASSNAMES, DEFAULT_BUTTON_VARIANT_CLASSNAMES } from '../constants'

type ButtonSize = 'thin' | 'sm' | 'md' | 'lg'

type Btn = {
  size: Record<ButtonSize, string>
} & BaseButtonClassSettings

export const buttonVariants = cva<Btn>(
  ['transition duration-300 ease-in-out rounded-md', 'hover:cursor-pointer', 'disabled:cursor-not-allowed'],
  {
    variants: {
      size: {
        thin: '',
        sm: 'py-1 px-4 text-xs',
        md: 'py-2 px-5 text-sm',
        lg: 'py-3 px-5 text-md',
      },
      variant: DEFAULT_BUTTON_VARIANT_CLASSNAMES,
      theme: DEFAULT_BUTTON_THEME_CLASSNAMES,
    },
    compoundVariants: [
      {
        variant: 'filled',
        theme: 'primary',
        className: 'border-purple-500 text-white bg-primary-default hover:bg-primary-hover disabled:text-white',
      },
      {
        variant: 'filled',
        theme: 'secondary',
        className: 'text-black hover:text-black bg-gray-200 hover:bg-gray-300 disabled:text-white',
      },
      {
        variant: 'filled',
        theme: 'danger',
        className: 'text-white hover:text-white bg-red-500 hover:bg-red-400 disabled:text-white',
      },
      {
        variant: 'outline',
        theme: 'primary',
        className:
          'text-secondary-default border-secondary-default hover:bg-secondary-hover hover:text-white focus:bg-secondary-pressed focus:text-white disabled:text-gray-200',
      },
      {
        variant: 'outline',
        theme: 'secondary',
        className:
          'text-gray-900 border-gray-200 hover:border-gray-900 focus:border-secondary-default focus:bg-gray-50 disabled:text-gray-400',
      },
      {
        variant: 'text',
        theme: 'primary',
        className: 'text-purple-500',
      },
    ],
  },
)

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className = '', size = 'md', variant = 'filled', type = 'button', theme = 'primary', disabled, ...props },
    ref,
  ) => {
    const combinedClassNames = buttonVariants({
      size,
      variant,
      theme: disabled ? 'secondary' : theme,
      class: className,
    })

    return (
      <button
        ref={ref}
        type={type}
        className={clsx(combinedClassNames, disabled && 'cursor-not-allowed')}
        {...props}
        disabled={disabled}
      />
    )
  },
)

Button.displayName = 'Button'

export default Button
