"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuestionsDeletePayload = exports.QuestionParamsSchema = exports.QuestionFindParamsSchema = exports.QuestionFindFilterFormSchema = exports.QuestionFindFilterParams = exports.QuestionFindOrderParams = exports.QuestionRelationsFilterParams = exports.QuestionsFindListResponseSchema = exports.QuestionCreateUpdateResponseSchema = exports.QuestionCreateUpdatePayload = exports.QuestionCreateUpdateAdditionalDataSchema = exports.QuestionWithRelationsSchema = exports.QuestionSchema = exports.QuestionEssayBaseSchema = exports.QuestionTextInputBaseSchema = exports.QuestionSortingBaseSchema = exports.QuestionMatchedPairsBaseSchema = exports.QuestionRankingBaseSchema = exports.QuestionMultipleChoiceBaseSchema = exports.QuestionSingleChoiceBaseSchema = exports.QuestionAbstractBaseSchema = exports.QuestionWithBaseEntity = exports.QuestionRelationFieldsSchema = exports.QuestionAdditionalDataSchema = exports.QuestionBaseSchema = exports.QuestionTypeSchema = exports.QuestionOptionsSchema = exports.QuestionOptionsEssaySchema = exports.QuestionOptionsTextInputSchema = exports.TextInputOption = exports.QuestionOptionsSortingSchema = exports.SortingOption = exports.QuestionOptionsMatchedPairsSchema = exports.MatchedPairsOptionsSchema = exports.MatchedPairsOptionSchema = exports.QuestionOptionsRankingSchema = exports.SingleRankingOption = exports.QuestionOptionsMultipleChoiceSchema = exports.QuestionOptionsSingleChoiceSchema = exports.MultipleOption = exports.SingleOption = exports.QuestionUnsignedPointsSchema = exports.QuestionSignedPointsSchema = exports.QuestionComplexitySchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const types_1 = require("./types");
const baseEntity_1 = require("../baseEntity");
const responses_1 = require("../../responses");
const common_1 = require("../../common");
const grade_1 = require("../grade");
const utils_1 = require("../../utils");
exports.QuestionComplexitySchema = typebox_1.Type.Enum(types_1.QuestionComplexity);
exports.QuestionSignedPointsSchema = typebox_1.Type.Number({
    minimum: -999.99,
    maximum: 999.99,
    multipleOf: 0.01,
});
exports.QuestionUnsignedPointsSchema = typebox_1.Type.Number({
    minimum: 0,
    maximum: 999.99,
    multipleOf: 0.01,
});
exports.SingleOption = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: typebox_1.Type.String(),
    is_correct: typebox_1.Type.Boolean(),
});
exports.MultipleOption = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: typebox_1.Type.String(),
    is_correct: typebox_1.Type.Boolean(),
    points: exports.QuestionSignedPointsSchema,
});
exports.QuestionOptionsSingleChoiceSchema = typebox_1.Type.Object({
    type: typebox_1.Type.Literal(types_1.QuestionType.SingleChoice),
    options: typebox_1.Type.Array(exports.SingleOption),
}, { additionalProperties: false });
exports.QuestionOptionsMultipleChoiceSchema = typebox_1.Type.Object({
    type: typebox_1.Type.Literal(types_1.QuestionType.MultipleChoice),
    options: typebox_1.Type.Array(exports.MultipleOption),
}, { additionalProperties: false });
exports.SingleRankingOption = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: typebox_1.Type.String(),
    position: typebox_1.Type.Integer(),
});
exports.QuestionOptionsRankingSchema = typebox_1.Type.Object({
    type: typebox_1.Type.Literal(types_1.QuestionType.Ranking),
    options: typebox_1.Type.Array(exports.SingleRankingOption),
}, { additionalProperties: false });
exports.MatchedPairsOptionSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: typebox_1.Type.String(),
    position: typebox_1.Type.Integer(),
});
exports.MatchedPairsOptionsSchema = typebox_1.Type.Object({
    left: typebox_1.Type.Array(exports.MatchedPairsOptionSchema),
    right: typebox_1.Type.Array(exports.MatchedPairsOptionSchema),
});
exports.QuestionOptionsMatchedPairsSchema = typebox_1.Type.Object({
    type: typebox_1.Type.Literal(types_1.QuestionType.MatchedPairs),
    options: exports.MatchedPairsOptionsSchema,
}, { additionalProperties: false });
exports.SortingOption = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: typebox_1.Type.String(),
    group: typebox_1.Type.String(),
});
exports.QuestionOptionsSortingSchema = typebox_1.Type.Object({
    type: typebox_1.Type.Literal(types_1.QuestionType.Sorting),
    options: typebox_1.Type.Array(exports.SortingOption),
}, { additionalProperties: false });
exports.TextInputOption = typebox_1.Type.Object({
    correct_answer: typebox_1.Type.String(),
});
exports.QuestionOptionsTextInputSchema = typebox_1.Type.Object({
    type: typebox_1.Type.Literal(types_1.QuestionType.TextInput),
    options: typebox_1.Type.Array(exports.TextInputOption),
}, { additionalProperties: false });
exports.QuestionOptionsEssaySchema = typebox_1.Type.Object({
    type: typebox_1.Type.Literal(types_1.QuestionType.Essay),
    options: typebox_1.Type.Object({}, { additionalProperties: false }),
}, { additionalProperties: false });
const QuestionOptionsSchemaArray = [
    exports.QuestionOptionsSingleChoiceSchema,
    exports.QuestionOptionsMultipleChoiceSchema,
    exports.QuestionOptionsRankingSchema,
    exports.QuestionOptionsMatchedPairsSchema,
    exports.QuestionOptionsSortingSchema,
    exports.QuestionOptionsTextInputSchema,
    exports.QuestionOptionsEssaySchema,
];
exports.QuestionOptionsSchema = (0, utils_1.OneOf)(QuestionOptionsSchemaArray.map((optionsSchema) => optionsSchema), {
    type: 'object',
    discriminator: { propertyName: 'type' },
    required: ['type'],
});
exports.QuestionTypeSchema = typebox_1.Type.Object({
    type: typebox_1.Type.Enum(types_1.QuestionType),
});
exports.QuestionBaseSchema = typebox_1.Type.Object({
    content: typebox_1.Type.String(),
    points: exports.QuestionUnsignedPointsSchema,
    complexity: exports.QuestionComplexitySchema,
    hint: (0, common_1.Nullable)(typebox_1.Type.String()),
    clarification: (0, common_1.Nullable)(typebox_1.Type.String()),
    grade: grade_1.GradeSchema,
    course_id: typebox_1.Type.Integer(),
    name: typebox_1.Type.String({ maxLength: 255 }),
});
exports.QuestionAdditionalDataSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: typebox_1.Type.String(),
});
exports.QuestionRelationFieldsSchema = typebox_1.Type.Object({
    tags: typebox_1.Type.Array(exports.QuestionAdditionalDataSchema),
    course: exports.QuestionAdditionalDataSchema,
});
exports.QuestionWithBaseEntity = typebox_1.Type.Composite([exports.QuestionBaseSchema, baseEntity_1.BaseEntitySchema]);
exports.QuestionAbstractBaseSchema = typebox_1.Type.Composite([
    exports.QuestionWithBaseEntity,
    // QuestionRelationFieldsSchema,
    typebox_1.Type.Object({
        type: typebox_1.Type.Enum(types_1.QuestionType),
        options: typebox_1.Type.Unknown(),
    }),
]);
exports.QuestionSingleChoiceBaseSchema = typebox_1.Type.Composite([
    exports.QuestionBaseSchema,
    baseEntity_1.BaseEntitySchema,
    exports.QuestionOptionsSingleChoiceSchema,
]);
exports.QuestionMultipleChoiceBaseSchema = typebox_1.Type.Composite([
    exports.QuestionBaseSchema,
    baseEntity_1.BaseEntitySchema,
    exports.QuestionOptionsMultipleChoiceSchema,
]);
exports.QuestionRankingBaseSchema = typebox_1.Type.Composite([
    exports.QuestionBaseSchema,
    baseEntity_1.BaseEntitySchema,
    exports.QuestionOptionsRankingSchema,
]);
exports.QuestionMatchedPairsBaseSchema = typebox_1.Type.Composite([
    exports.QuestionBaseSchema,
    baseEntity_1.BaseEntitySchema,
    exports.QuestionOptionsMatchedPairsSchema,
]);
exports.QuestionSortingBaseSchema = typebox_1.Type.Composite([
    exports.QuestionBaseSchema,
    baseEntity_1.BaseEntitySchema,
    exports.QuestionOptionsSortingSchema,
]);
exports.QuestionTextInputBaseSchema = typebox_1.Type.Composite([
    exports.QuestionBaseSchema,
    baseEntity_1.BaseEntitySchema,
    exports.QuestionOptionsTextInputSchema,
]);
exports.QuestionEssayBaseSchema = typebox_1.Type.Composite([
    exports.QuestionBaseSchema,
    baseEntity_1.BaseEntitySchema,
    exports.QuestionOptionsEssaySchema,
]);
exports.QuestionSchema = (0, utils_1.OneOf)([
    exports.QuestionSingleChoiceBaseSchema,
    exports.QuestionMultipleChoiceBaseSchema,
    exports.QuestionRankingBaseSchema,
    exports.QuestionMatchedPairsBaseSchema,
    exports.QuestionSortingBaseSchema,
    exports.QuestionTextInputBaseSchema,
    exports.QuestionEssayBaseSchema,
], {
    type: 'object',
    discriminator: { propertyName: 'type' },
    required: ['type'],
});
exports.QuestionWithRelationsSchema = (0, utils_1.OneOf)(QuestionOptionsSchemaArray.map((optionsSchema) => typebox_1.Type.Composite([exports.QuestionBaseSchema, baseEntity_1.BaseEntitySchema, optionsSchema, exports.QuestionRelationFieldsSchema])), {
    type: 'object',
    discriminator: { propertyName: 'type' },
    required: ['type'],
});
exports.QuestionCreateUpdateAdditionalDataSchema = typebox_1.Type.Object({
    tags_ids: typebox_1.Type.Array(typebox_1.Type.Integer()),
});
exports.QuestionCreateUpdatePayload = (0, utils_1.OneOf)(QuestionOptionsSchemaArray.map((optionsSchema) => typebox_1.Type.Composite([exports.QuestionBaseSchema, optionsSchema, typebox_1.Type.Optional(exports.QuestionCreateUpdateAdditionalDataSchema)])), {
    type: 'object',
    discriminator: { propertyName: 'type' },
    required: ['type'],
});
exports.QuestionCreateUpdateResponseSchema = typebox_1.Type.Pick(exports.QuestionWithBaseEntity, ['id']);
exports.QuestionsFindListResponseSchema = (0, responses_1.PaginatedList)(exports.QuestionWithRelationsSchema);
exports.QuestionRelationsFilterParams = typebox_1.Type.Object({
    tags: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.Integer())),
});
exports.QuestionFindOrderParams = typebox_1.Type.Partial(typebox_1.Type.Pick(typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.QuestionTypeSchema, exports.QuestionBaseSchema]), [
    'type',
    'points',
    'complexity',
    'course_id',
    'grade',
    'name',
    'created_at',
    'updated_at',
]));
exports.QuestionFindFilterParams = typebox_1.Type.Composite([exports.QuestionFindOrderParams, exports.QuestionRelationsFilterParams]);
exports.QuestionFindFilterFormSchema = typebox_1.Type.Partial(typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.QuestionFindFilterParams, ['course_id', 'grade', 'type', 'complexity', 'tags']),
    typebox_1.Type.Object({
        course_id: common_1.FormLookupOption,
        grade: common_1.FormLookupOption,
        type: common_1.FormLookupOption,
        complexity: common_1.FormLookupOption,
        tags: typebox_1.Type.Array(common_1.FormLookupOption),
    }),
]));
exports.QuestionFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.QuestionFindFilterParams,
    order: exports.QuestionFindOrderParams,
});
exports.QuestionParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
});
exports.QuestionsDeletePayload = typebox_1.Type.Object({
    ids: typebox_1.Type.Array(typebox_1.Type.Integer()),
});
