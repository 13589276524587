import { clsx } from 'clsx'
import SpinnerIcon from '../Icons/Spinner'

interface SpinnerProps {
  className?: string
}

export const Spinner = ({ className = 'size-8' }: SpinnerProps) => (
  <SpinnerIcon className={clsx('animate-spin', className)} />
)
