"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuestionComplexity = exports.QuestionType = void 0;
var QuestionType;
(function (QuestionType) {
    QuestionType["MultipleChoice"] = "MultipleChoice";
    QuestionType["SingleChoice"] = "SingleChoice";
    QuestionType["Ranking"] = "Ranking";
    QuestionType["MatchedPairs"] = "MatchedPairs";
    QuestionType["Sorting"] = "Sorting";
    QuestionType["TextInput"] = "TextInput";
    QuestionType["FileUpload"] = "FileUpload";
    QuestionType["Essay"] = "Essay";
    QuestionType["DnDToText"] = "DnDToText";
})(QuestionType || (exports.QuestionType = QuestionType = {}));
var QuestionComplexity;
(function (QuestionComplexity) {
    QuestionComplexity["Easy"] = "Easy";
    QuestionComplexity["Medium"] = "Medium";
    QuestionComplexity["Hard"] = "Hard";
})(QuestionComplexity || (exports.QuestionComplexity = QuestionComplexity = {}));
