import { useQuery } from '@tanstack/react-query'
import { useService } from 'src/shared/hooks/di'
import { UserResponse } from '@repo/contracts'
import { UsersAPI } from 'src/shared/api/users/api'
import { USERS_QUERY_KEYS } from 'src/shared/api/users/constants'
import { MeStore } from '../stores/MeStore'

const useFetchMe = () => {
  const usersAPI = useService(UsersAPI)
  const { setMe } = useService(MeStore)
  const { isLoading } = useQuery<UserResponse, Error>({
    queryKey: USERS_QUERY_KEYS.me(),
    queryFn: () =>
      usersAPI.me().then((response) => {
        setMe(response.data)

        return response.data
      }),
    refetchOnWindowFocus: false,
  })

  return { isLoading }
}

export default useFetchMe
