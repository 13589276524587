"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OkMessage = exports.FindParams = exports.PaginationParams = exports.OrderParams = exports.PaginatedList = void 0;
const typebox_1 = require("@sinclair/typebox");
const index_1 = require("./index");
const PaginatedList = (schema) => typebox_1.Type.Object({
    items: typebox_1.Type.Array(schema),
    total: typebox_1.Type.Integer(),
}, { additionalProperties: false });
exports.PaginatedList = PaginatedList;
const OrderParams = (schema) => typebox_1.Type.Object({
    field: typebox_1.Type.KeyOf(schema, { examples: schema?.properties ? [Object.keys(schema.properties)[0]] : [] }),
    direction: typebox_1.Type.Enum(index_1.OrderDirection, { examples: [index_1.OrderDirection.DESC] }),
});
exports.OrderParams = OrderParams;
exports.PaginationParams = typebox_1.Type.Object({
    limit: typebox_1.Type.Integer({ minimum: 1, maximum: 1000, examples: [10], default: 10 }),
    offset: typebox_1.Type.Integer({ minimum: 0, examples: [0], default: 0 }),
});
const FindParams = ({ filters, exclude, order, }) => {
    const fields = {
        filters: typebox_1.Type.Optional(filters || typebox_1.Type.Null()),
        exclude: exclude !== undefined ? typebox_1.Type.Optional(exclude) : typebox_1.Type.Optional(typebox_1.Type.Null()),
        order: typebox_1.Type.Optional(order ? (0, exports.OrderParams)(order) : typebox_1.Type.Null()),
    };
    return typebox_1.Type.Composite([typebox_1.Type.Object(fields, { additionalProperties: false }), exports.PaginationParams], {
        additionalProperties: false,
    });
};
exports.FindParams = FindParams;
exports.OkMessage = typebox_1.Type.Object({
    message: typebox_1.Type.Literal('Ok'),
});
