export const DotIcon = () => {
  return (
    <svg
      height="20"
      width="20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <g>
        <circle cx="10" cy="10" r="2" />
      </g>
    </svg>
  )
}
