"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClassroomTeacherFindResponseSchema = exports.ClassroomTeacherSchemaResponse = exports.ClassroomTeacherFindParamsSchema = exports.ClassroomTeacherFindFilterParamsSchema = exports.ClassroomChangeResponsibilitySchema = exports.ClassroomDetachSchema = exports.ClassroomAttachSchema = exports.ClassroomFindResponseSchema = exports.ClassroomFindParamsSchema = exports.ClassroomFindOrderSchema = exports.ClassroomFindSchemaResponse = exports.ClassroomFindFilterFormSchema = exports.ClassroomFindFilterParamsSchema = exports.ClassroomParamsSchema = exports.ClassroomSchemaResponse = exports.ClassroomCreateUpdateFormSchema = exports.ClassroomCreateUpdateSchema = exports.ClassroomSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const common_1 = require("../../common");
const calendar_plan_1 = require("../calendar-plan");
const responses_1 = require("../../responses");
const types_1 = require("./types");
exports.ClassroomSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: typebox_1.Type.String({ minLength: 1, maxLength: 50 }),
    max_students_count: (0, common_1.Nullable)(typebox_1.Type.Integer({ minimum: 0 })),
    calendar_plan_id: (0, common_1.Nullable)(typebox_1.Type.Integer()),
    age_group_id: typebox_1.Type.Integer(),
    education_form: typebox_1.Type.Enum(types_1.EducationForm),
}, { additionalProperties: false });
exports.ClassroomCreateUpdateSchema = typebox_1.Type.Omit(exports.ClassroomSchema, ['id', 'age_group_id', 'name']);
exports.ClassroomCreateUpdateFormSchema = typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.ClassroomCreateUpdateSchema, ['education_form', 'calendar_plan_id']),
    typebox_1.Type.Object({
        education_form: common_1.FormLookupOption,
        calendar_plan_id: (0, common_1.Nullable)(common_1.FormLookupOption),
    }),
]);
exports.ClassroomSchemaResponse = typebox_1.Type.Composite([
    typebox_1.Type.Pick(exports.ClassroomSchema, ['id', 'name', 'max_students_count', 'education_form']),
    typebox_1.Type.Object({
        calendar_plan_id: (0, common_1.Nullable)(calendar_plan_1.CalendarPlanSchema.properties.id),
        calendar_plan_name: (0, common_1.Nullable)(calendar_plan_1.CalendarPlanSchema.properties.name),
    }, { additionalProperties: false }),
]);
exports.ClassroomParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    classroomId: typebox_1.Type.Integer(),
});
exports.ClassroomFindFilterParamsSchema = typebox_1.Type.Object({
    name: typebox_1.Type.Optional(typebox_1.Type.String()),
    calendar_plan_id: typebox_1.Type.Optional(typebox_1.Type.Integer()),
    education_form: typebox_1.Type.Optional(typebox_1.Type.Enum(types_1.EducationForm)),
});
exports.ClassroomFindFilterFormSchema = typebox_1.Type.Partial(typebox_1.Type.Composite([
    typebox_1.Type.Omit(exports.ClassroomFindFilterParamsSchema, ['education_form', 'calendar_plan_id']),
    typebox_1.Type.Object({
        education_form: common_1.FormLookupOption,
        calendar_plan_id: common_1.FormLookupOption,
    }),
]));
exports.ClassroomFindSchemaResponse = typebox_1.Type.Composite([
    exports.ClassroomSchemaResponse,
    typebox_1.Type.Object({
        users_count: typebox_1.Type.Integer(),
        teachers: typebox_1.Type.Array(typebox_1.Type.Object({
            user_id: typebox_1.Type.Integer(),
            first_name: typebox_1.Type.String(),
            last_name: typebox_1.Type.String(),
            middle_name: (0, common_1.Nullable)(typebox_1.Type.String()),
            responsibility: typebox_1.Type.String(),
        })),
    }, { additionalProperties: false }),
]);
exports.ClassroomFindOrderSchema = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.ClassroomFindSchemaResponse, [
    'name',
    'users_count',
]));
exports.ClassroomFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.ClassroomFindFilterParamsSchema,
    order: exports.ClassroomFindOrderSchema,
});
exports.ClassroomFindResponseSchema = (0, responses_1.PaginatedList)(exports.ClassroomFindSchemaResponse);
exports.ClassroomAttachSchema = typebox_1.Type.Array(typebox_1.Type.Object({ userId: typebox_1.Type.Integer(), responsibility: typebox_1.Type.Enum(types_1.Responsibilities) }, { additionalProperties: false }));
exports.ClassroomDetachSchema = typebox_1.Type.Object({ userIds: typebox_1.Type.Array(typebox_1.Type.Integer()) }, { additionalProperties: false });
exports.ClassroomChangeResponsibilitySchema = typebox_1.Type.Object({
    userIds: typebox_1.Type.Array(typebox_1.Type.Integer()),
    responsibility: typebox_1.Type.Enum(types_1.Responsibilities),
}, { additionalProperties: false });
exports.ClassroomTeacherFindFilterParamsSchema = typebox_1.Type.Object({
    first_name: typebox_1.Type.Optional(typebox_1.Type.String()),
    last_name: typebox_1.Type.Optional(typebox_1.Type.String()),
    middle_name: typebox_1.Type.Optional(typebox_1.Type.String()),
    email: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'email' })),
    code: typebox_1.Type.Optional(typebox_1.Type.Integer()),
    position: typebox_1.Type.Optional(typebox_1.Type.Integer()),
});
exports.ClassroomTeacherFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.ClassroomTeacherFindFilterParamsSchema,
});
exports.ClassroomTeacherSchemaResponse = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    first_name: typebox_1.Type.String(),
    last_name: typebox_1.Type.String(),
    middle_name: (0, common_1.Nullable)(typebox_1.Type.String()),
    responsibility: typebox_1.Type.Enum(types_1.Responsibilities),
    code: typebox_1.Type.Integer(),
});
exports.ClassroomTeacherFindResponseSchema = (0, responses_1.PaginatedList)(exports.ClassroomTeacherSchemaResponse);
