import {
  ActionType,
  RoleCreateUpdateParamsType,
  RoleFindParamsType,
  RoleFindResponseType,
  RoleIdsQueryParamsType,
  RoleResponseType,
  SubjectType,
  UsersByRoleFindParamsSchemaType,
  UsersPositionFindResponseSchemaType,
} from '@repo/contracts'
import { apiClient } from 'src/shared/api/clients'
import { injectable, singleton } from 'tsyringe'

@singleton()
@injectable()
export class RolesAPI {
  constructor() {}

  findRoles(data: RoleFindParamsType) {
    return apiClient.post<RoleFindResponseType>('roles/find', data)
  }

  getRoleById(id: number) {
    return apiClient.get<RoleResponseType>(`roles/${id}`)
  }

  createRole(data: RoleCreateUpdateParamsType) {
    return apiClient.post<RoleResponseType>('roles', data)
  }

  updateRoleById(id: number, data: RoleCreateUpdateParamsType) {
    return apiClient.put(`roles/${id}`, data)
  }

  deleteRoleById(id: number) {
    return apiClient.delete(`roles/${id}`)
  }

  getUsersByRoleId(id: number, params: UsersByRoleFindParamsSchemaType) {
    return apiClient.post<UsersPositionFindResponseSchemaType>(`roles/${id}/users/find`, params)
  }

  assignRoleToUsers(id: number, data: number[]) {
    return apiClient.post(`roles/${id}/users/assign`, data)
  }

  unassignRoleToUsers(id: number, data: number[]) {
    return apiClient.post(`roles/${id}/users/unassign`, data)
  }

  getPermissionsByRoles(params: RoleIdsQueryParamsType) {
    return apiClient.post<{ permissions: Record<SubjectType, ActionType[]> }>(`roles/permissions`, params)
  }
}
