import { AnyAbility } from '@casl/ability'
import { ActionType, SubjectType } from '@repo/contracts'

export const checkPermissions = (
  ability: AnyAbility,
  permissions?: Partial<Record<SubjectType, ActionType[]>>,
): boolean => {
  if (!permissions) return true

  return Object.entries(permissions).some(([key, value]) => value.some((action) => ability.can(action, key)))
}
