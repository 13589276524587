import ROUTES from '../constants'
import PublicLayout from 'src/components/Layouts/PublicLayout'
import { lazy, Suspense } from 'react'
import ModuleSpinner from '@repo/ui/ModuleSpinner'

const SignInPage = lazy(() => import('src/pages/AuthPages/SignInPage'))
const SignUp = lazy(() => import('src/modules/AuthModule/SignUp'))
const SignUpConfirmation = lazy(() => import('src/modules/AuthModule/SingUpConfirmation'))
const ConfirmSSOPage = lazy(() => import('src/pages/AuthPages/ConfirmSSOPage'))
const RecoverPasswordPage = lazy(() => import('src/pages/AuthPages/RecoverPasswordPage'))
const ChangePasswordPage = lazy(() => import('src/pages/AuthPages/ChangePasswordPage'))

export default [
  {
    path: ROUTES.AUTH.SIGN_IN,
    element: <PublicLayout />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<ModuleSpinner />}>
            <SignInPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: ROUTES.AUTH.SIGN_UP,
    element: <PublicLayout />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<ModuleSpinner />}>
            <SignUp />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: ROUTES.AUTH.SIGN_UP_CONFIRMATION,
    element: <PublicLayout />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<ModuleSpinner />}>
            <SignUpConfirmation />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: ROUTES.AUTH.CONFIRM_SSO,
    element: <PublicLayout />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<ModuleSpinner />}>
            <ConfirmSSOPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: ROUTES.AUTH.RECOVER_PASSWORD,
    element: <PublicLayout />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<ModuleSpinner />}>
            <RecoverPasswordPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: ROUTES.AUTH.CHANGE_PASSWORD,
    element: <PublicLayout />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<ModuleSpinner />}>
            <ChangePasswordPage />
          </Suspense>
        ),
      },
    ],
  },
]
