interface CloseIconProps {
  width?: number
  height?: number
}

export const CloseIcon = ({ width = 20, height = 20 }: CloseIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
      <path
        d="M11.1884 10.0103L15.5992 5.59949C15.6788 5.52262 15.7423 5.43066 15.786 5.32899C15.8297 5.22732 15.8527 5.11797 15.8536 5.00732C15.8546 4.89667 15.8335 4.78694 15.7916 4.68453C15.7497 4.58211 15.6878 4.48907 15.6096 4.41083C15.5313 4.33258 15.4383 4.2707 15.3359 4.2288C15.2335 4.1869 15.1237 4.16582 15.0131 4.16678C14.9024 4.16774 14.7931 4.19073 14.6914 4.2344C14.5897 4.27808 14.4978 4.34156 14.4209 4.42116L10.0101 8.83199L5.59924 4.42116C5.44208 4.26936 5.23157 4.18536 5.01308 4.18726C4.79458 4.18916 4.58557 4.2768 4.43106 4.43131C4.27656 4.58581 4.18891 4.79482 4.18702 5.01332C4.18512 5.23182 4.26911 5.44232 4.42091 5.59949L8.83174 10.0103L4.42091 14.4212C4.34132 14.498 4.27783 14.59 4.23416 14.6917C4.19049 14.7933 4.1675 14.9027 4.16654 15.0133C4.16557 15.124 4.18666 15.2337 4.22856 15.3361C4.27046 15.4385 4.33234 15.5316 4.41058 15.6098C4.48883 15.6881 4.58187 15.7499 4.68428 15.7918C4.7867 15.8337 4.89643 15.8548 5.00708 15.8539C5.11773 15.8529 5.22708 15.8299 5.32875 15.7862C5.43042 15.7426 5.52237 15.6791 5.59924 15.5995L10.0101 11.1887L14.4209 15.5995C14.5781 15.7513 14.7886 15.8353 15.0071 15.8334C15.2256 15.8315 15.4346 15.7438 15.5891 15.5893C15.7436 15.4348 15.8312 15.2258 15.8331 15.0073C15.835 14.7888 15.751 14.5783 15.5992 14.4212L11.1884 10.0103Z"
        fill="currentColor"
      />
    </svg>
  )
}
