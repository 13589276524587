import { useCallback, useEffect } from 'react'

export const useLocalStorage = () => {
  const setItem = useCallback((key: string, value: string) => {
    window.localStorage.setItem(key, value)
  }, [])

  const getItem = useCallback((key: string, options?: { deserialize?: boolean }) => {
    if (options?.deserialize) {
      return JSON.parse(window.localStorage.getItem(key) || '')
    }

    return window.localStorage.getItem(key)
  }, [])

  const removeItem = useCallback((key: string) => {
    window.localStorage.removeItem(key)
  }, [])

  const clear = useCallback(() => {
    window.localStorage.clear()
  }, [])

  return { setItem, getItem, removeItem, clear }
}
export const useLocalStorageEvent = (callback: (event: StorageEvent) => void) => {
  const storageEventCallback = useCallback(
    (event: StorageEvent) => {
      callback(event)
    },
    [callback],
  )

  useEffect(() => {
    window.addEventListener('storage', storageEventCallback)

    return () => {
      window.removeEventListener('storage', storageEventCallback)
    }
  }, [storageEventCallback])
}
