"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Assignment = exports.COURSE_STATUSES = void 0;
var COURSE_STATUSES;
(function (COURSE_STATUSES) {
    COURSE_STATUSES[COURSE_STATUSES["ACTIVE"] = 0] = "ACTIVE";
    COURSE_STATUSES[COURSE_STATUSES["INACTIVE"] = 1] = "INACTIVE";
})(COURSE_STATUSES || (exports.COURSE_STATUSES = COURSE_STATUSES = {}));
var Assignment;
(function (Assignment) {
    Assignment["Auto"] = "Auto";
    Assignment["Static"] = "Static";
})(Assignment || (exports.Assignment = Assignment = {}));
