import clsx from 'clsx'
import { NavLink } from 'react-router-dom'
import { getNavLinkClassName } from './MenuListItemLinkStyles'
import { MenuListItemLinkProps, NavLinkClassNameCbOptions } from './MenuListItemLinkTypes'
import { useAsideContext } from '@repo/ui/PrivateLayout'
import { useTranslation } from 'react-i18next'

export const MenuListItemLink = ({ path, label, leftIcon, rightIcon }: MenuListItemLinkProps) => {
  const { t } = useTranslation('menu')
  const isAsideOpen = useAsideContext()
  const getNavClassLink = ({ isActive }: NavLinkClassNameCbOptions) =>
    clsx('flex w-full items-center gap-1.5 overflow-hidden rounded px-2.5 py-2', getNavLinkClassName(isActive))

  return (
    <li
      className={clsx(
        {
          'w-full': isAsideOpen,
        },
        'list-none',
      )}
    >
      <NavLink className={getNavClassLink} to={path}>
        {leftIcon && <div className='size-5 shrink-0'>{leftIcon}</div>}
        {isAsideOpen && <span className={clsx('font-medium')}>{t(label)}</span>}
        {rightIcon}
      </NavLink>
    </li>
  )
}
