"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromocodeUsageCountValue = exports.PromocodeStrictDiscountValue = exports.PromocodeDiscountPercentageValue = exports.PromocodeType = exports.PromocodeUsageType = exports.PromocodeStatus = exports.PromocodeConsultationType = exports.PromocodeDiscountType = void 0;
var PromocodeDiscountType;
(function (PromocodeDiscountType) {
    PromocodeDiscountType["Percentage"] = "Percentage";
    PromocodeDiscountType["Strict"] = "Strict";
})(PromocodeDiscountType || (exports.PromocodeDiscountType = PromocodeDiscountType = {}));
var PromocodeConsultationType;
(function (PromocodeConsultationType) {
    PromocodeConsultationType["Group"] = "Group";
    PromocodeConsultationType["Individual"] = "Individual";
})(PromocodeConsultationType || (exports.PromocodeConsultationType = PromocodeConsultationType = {}));
var PromocodeStatus;
(function (PromocodeStatus) {
    PromocodeStatus["Active"] = "Active";
    PromocodeStatus["Ended"] = "Ended";
})(PromocodeStatus || (exports.PromocodeStatus = PromocodeStatus = {}));
var PromocodeUsageType;
(function (PromocodeUsageType) {
    PromocodeUsageType["OneTimeDiscount"] = "OneTimeDiscount";
    PromocodeUsageType["OneTimeDiscountWithProlongation"] = "OneTimeDiscountWithProlongation";
})(PromocodeUsageType || (exports.PromocodeUsageType = PromocodeUsageType = {}));
var PromocodeType;
(function (PromocodeType) {
    PromocodeType["Course"] = "course";
    PromocodeType["Package"] = "package";
    PromocodeType["Consultation"] = "consultation";
})(PromocodeType || (exports.PromocodeType = PromocodeType = {}));
var PromocodeDiscountPercentageValue;
(function (PromocodeDiscountPercentageValue) {
    PromocodeDiscountPercentageValue[PromocodeDiscountPercentageValue["Min"] = 1] = "Min";
    PromocodeDiscountPercentageValue[PromocodeDiscountPercentageValue["Max"] = 100] = "Max";
})(PromocodeDiscountPercentageValue || (exports.PromocodeDiscountPercentageValue = PromocodeDiscountPercentageValue = {}));
var PromocodeStrictDiscountValue;
(function (PromocodeStrictDiscountValue) {
    PromocodeStrictDiscountValue[PromocodeStrictDiscountValue["Min"] = 1] = "Min";
    PromocodeStrictDiscountValue[PromocodeStrictDiscountValue["Max"] = 10000] = "Max";
})(PromocodeStrictDiscountValue || (exports.PromocodeStrictDiscountValue = PromocodeStrictDiscountValue = {}));
var PromocodeUsageCountValue;
(function (PromocodeUsageCountValue) {
    PromocodeUsageCountValue[PromocodeUsageCountValue["Min"] = 1] = "Min";
    PromocodeUsageCountValue[PromocodeUsageCountValue["Max"] = 100000] = "Max";
})(PromocodeUsageCountValue || (exports.PromocodeUsageCountValue = PromocodeUsageCountValue = {}));
