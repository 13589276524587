import { MutableRefObject, useEffect } from 'react'

export const useClickAway = (
  isVisible: boolean,
  ref: MutableRefObject<HTMLElement | null>,
  callback: () => void,
  sourceRef?: MutableRefObject<HTMLElement | SVGSVGElement | null> | null,
) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!isVisible || (sourceRef?.current && sourceRef.current?.contains?.(event.target as Node))) {
        return
      }
      if (ref.current && !event.composedPath().includes(ref.current)) {
        callback()
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [ref, isVisible, callback, sourceRef])
}
