"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseEntitySchema = exports.ID = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.ID = typebox_1.Type.Integer();
exports.BaseEntitySchema = typebox_1.Type.Object({
    id: exports.ID,
    created_at: typebox_1.Type.String({ format: 'date-time' }),
    updated_at: typebox_1.Type.String({ format: 'date-time' }),
});
