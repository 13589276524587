export enum CALENDAR_VIEW {
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
}

export const CALENDAR_FORMATS = {
  TIME: 'HH:mm',
}

export const CALENDAR_CONFIG = {
  allDayMaxRows: 3,
  step: 60,
  timeslots: 1,
}
