import { FC, ReactNode, useEffect, useRef } from 'react'
import usePopperWindow from './usePopperWindow'
import { createPortal } from 'react-dom'
import { useClickAway } from '../hooks/useClickAway'
import isEmpty from 'lodash/isEmpty'

import { Placement } from '@popperjs/core'
import { MutableRefObject } from 'react'

export interface PopperProps {
  isOpen?: boolean
  onClose: () => void
  children: ReactNode
  className?: string
  placement?: Placement
  sourceRef: MutableRefObject<HTMLElement | SVGSVGElement | null>
  closeOnClickOnSource?: boolean
  offsetSkidding?: number
  offsetDistance?: number
  inPortal?: boolean
}

const Popper: FC<PopperProps> = ({
  isOpen = false,
  onClose,
  placement,
  className = '',
  sourceRef,
  children,
  offsetDistance,
  offsetSkidding,
  closeOnClickOnSource,
  inPortal = true,
}) => {
  const { visible, setVisibility, popperRef, styles, attributes, update } = usePopperWindow(
    sourceRef,
    placement,
    offsetDistance,
    offsetSkidding,
  )
  useClickAway(visible, popperRef, onClose, closeOnClickOnSource ? undefined : sourceRef)
  const ref = useRef<HTMLBodyElement | null>(null)

  useEffect(() => {
    setVisibility(isOpen)

    return () => {
      setVisibility(false)
    }
  }, [isOpen, setVisibility])

  useEffect(() => {
    if (visible) {
      update?.()
    }
  }, [update, visible])

  useEffect(() => {
    ref.current = document.querySelector('body')
  }, [])

  if (inPortal) {
    return isOpen && ref.current
      ? createPortal(
          <div
            ref={popperRef}
            style={styles.popper}
            {...attributes.popper}
            className={`${className} ${isEmpty(attributes.popper) ? 'hidden' : 'visible'} z-50`}
            data-label="popper"
          >
            {isOpen ? children : null}
          </div>,
          ref.current!,
        )
      : null
  }

  return (
    <div
      ref={popperRef}
      style={styles.popper}
      {...attributes.popper}
      className={`${className} ${isEmpty(attributes.popper) ? 'hidden' : 'visible'} z-50`}
      data-label="popper"
    >
      {isOpen ? children : null}
    </div>
  )
}

export default Popper
