"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EducationForm = exports.Responsibilities = void 0;
var Responsibilities;
(function (Responsibilities) {
    Responsibilities["ClassroomTeacher"] = "ClassroomTeacher";
    Responsibilities["Mentor"] = "Mentor";
})(Responsibilities || (exports.Responsibilities = Responsibilities = {}));
var EducationForm;
(function (EducationForm) {
    EducationForm["FullTime"] = "FullTime";
    EducationForm["Distance"] = "Distance";
    EducationForm["External"] = "External";
    EducationForm["Individual"] = "Individual";
})(EducationForm || (exports.EducationForm = EducationForm = {}));
