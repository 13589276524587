import ReactSelect, { components, Props, DropdownIndicatorProps, CSSObjectWithLabel, StylesConfig } from 'react-select'
import { BaseControlProps } from '../types'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { clsx } from 'clsx'
import { RefCallBack } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { SELECTOR_PORTALS_ELEMENT_ID } from '../constants'

export interface SelectProps<OptionType, isMulti extends boolean>
  extends Partial<Props<OptionType, isMulti>>,
    BaseControlProps {
  containerClassName?: string
  formRef?: RefCallBack
  disabled?: boolean
  customStyles?: StylesConfig<OptionType, isMulti>
  inPortal?: boolean
}

const Z_INDEX_MENU = 100

const CUSTOM_STYLES = {
  menuPortal: (provided: CSSObjectWithLabel) => ({
    ...provided,
    zIndex: Z_INDEX_MENU,
  }),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
    zIndex: Z_INDEX_MENU,
  }),
}

const DropdownIndicator = <OptionType, isMulti extends boolean>(props: DropdownIndicatorProps<OptionType, isMulti>) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon className="size-3.5 text-gray-500" />
    </components.DropdownIndicator>
  )
}

const Select = <OptionType, isMulti extends boolean>({
  label,
  id,
  name,
  error,
  hint,
  containerClassName,
  customStyles,
  formRef,
  inPortal,
  ...rest
}: SelectProps<OptionType, isMulti>) => {
  const { t } = useTranslation('common')
  const elementId = id || name
  const [portalTarget] = useState<HTMLElement | null>(() =>
    inPortal ? document.getElementById(SELECTOR_PORTALS_ELEMENT_ID) : null,
  )

  return (
    <div className={clsx(containerClassName)}>
      {label && (
        <label htmlFor={elementId} className="mb-1 block text-sm font-medium" data-testid={`label-${id || name}`}>
          {label}
        </label>
      )}
      <ReactSelect<OptionType, isMulti>
        {...rest}
        inputId={elementId}
        className={clsx('react-select-container', { 'react-select-error': Boolean(error) })}
        classNamePrefix="react-select"
        ref={formRef}
        noOptionsMessage={() => t('selectNoOption')}
        components={{
          DropdownIndicator: (props) => <DropdownIndicator<OptionType, isMulti> {...props} />,
        }}
        menuPortalTarget={portalTarget}
        styles={{
          ...CUSTOM_STYLES,
          ...customStyles,
          valueContainer: (base, props) => ({
            ...base,
            paddingLeft: '16px',
            ...customStyles?.valueContainer?.(base, props),
          }),
        }}
      />
      {hint && <p data-testid={`hint-${elementId}`}>{hint}</p>}
      {error && (
        <p data-testid={`error-${elementId}`} className="text-sm text-red-500">
          {error}
        </p>
      )}
    </div>
  )
}

export default Select
