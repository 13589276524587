import { keepPreviousData, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { DefaultErrorFunction, SetErrorFunction } from '@sinclair/typebox/errors'
import { RouterProvider } from 'react-router-dom'
import { Slide, ToastContainer } from 'react-toastify'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { MAX_DEV_RETRIES, MAX_PROD_RETRIES } from 'src/shared/constants/query.ts'
import bootstrap from 'src/shared/lib/bootstrap.ts'
import LocalizationGuard from 'src/shared/guards/LocalizationGuard'
import { ROUTER } from './router'

import fakeData from '../shared/api/fakeData.ts'

import 'react-toastify/dist/ReactToastify.css'

bootstrap()

SetErrorFunction((error) => {
  return error?.schema?.errorMessage ?? DefaultErrorFunction(error)
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      placeholderData: keepPreviousData,
      retry: import.meta.env.MODE === 'development' ? MAX_DEV_RETRIES : MAX_PROD_RETRIES,
    },
  },
})

if (import.meta.env.MODE === 'development') fakeData()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationGuard namespace={['enum', 'validation', 'table', 'menu', 'common']}>
        <RouterProvider router={ROUTER} />
      </LocalizationGuard>
      <ToastContainer hideProgressBar transition={Slide} />
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

export default App
