import { useCallback } from 'react'
import useDecodeJWTPayload from 'src/shared/hooks/useDecodeJWTPayload.ts'
import { JWTPayload } from 'src/shared/types/jwt.ts'

const useJWTPayload = () => {
  const decode = useDecodeJWTPayload()

  return useCallback(
    (jwt: string) => {
      const { exp, id, email, roles } = JSON.parse(decode(jwt)) as JWTPayload

      return { user: { id, email, roles: roles?.map(Number) ?? [] }, expiredAt: exp * 1000 }
    },
    [decode],
  )
}

export default useJWTPayload
