import { UkraineIcon, USAIcon } from '@repo/ui/icons'
import { LocalizationLanguages } from 'src/shared/lib/constants'

export type LanguageOption = { value: keyof typeof LocalizationLanguages; label: JSX.Element }

const generateLabel = (
  IconComponent: React.ElementType,
  label = '',
  className = 'flex items-center gap-2 text-sm font-semibold text-gray-500',
) => {
  return (
    <div className={className}>
      <IconComponent className='w-5' /> {label}
    </div>
  )
}

export const languageOptions: Array<LanguageOption> = [
  {
    value: 'uk',
    label: generateLabel(UkraineIcon, 'UA'),
  },
  {
    value: 'en',
    label: generateLabel(USAIcon, 'ENG'),
  },
]

export const simplifiedLanguageOptions: Array<LanguageOption> = [
  {
    value: 'uk',
    label: generateLabel(UkraineIcon),
  },
  {
    value: 'en',
    label: generateLabel(USAIcon),
  },
]
