import { Languages } from '../lib/interfaces'

export const MAX_PROD_RETRIES = 3
export const MAX_DEV_RETRIES = 2

export const LOCALIZATION_QUERY_KEYS = {
  root: ['localization'],
  namespace: function (locale: Languages, key?: string | string[], populate?: Record<string, string>) {
    return [this.root[0], locale, key, populate].filter(Boolean)
  },
}

export const DEFAULT_STALE_TIME = 1 * 60 * 1000
export const DEFAULT_LOOKUP_OPTIONS_STALE_TIME = 5 * 60 * 1000
