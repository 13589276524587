import ROUTES from '../constants'
import { lazy, Suspense } from 'react'
import ModuleSpinner from '@repo/ui/ModuleSpinner'

const ElementsListPage = lazy(() => import('src/pages/ElementsPages/ElementsListPage'))
const ElementsPreviewPage = lazy(() => import('src/pages/ElementsPages/ElementsPreviewPage'))

export default [
  {
    path: ROUTES.LESSONS.ELEMENTS,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <ElementsListPage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.LESSONS.PREVIEW,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <ElementsPreviewPage />
      </Suspense>
    ),
  },
]
