"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserFindFilterParamsSchema = exports.PositionDetailsResponseSchema = exports.CreateUpdateResponseSchema = exports.UsersPositionFindResponseSchema = exports.UserPositionFindItemSchema = exports.PositionFindResponseSchema = exports.PositionListItemSchema = exports.PositionQueryParamsSchema = exports.PositionAssignToStaffParamsSchema = exports.UsersPositionFindParamsSchema = exports.PositionsFindParamsSchema = exports.PositionsFindFilterParamsSchema = exports.PositionCreateUpdateSchema = exports.InternalPositionCreateUpdateSchema = exports.PositionFindOrderParams = exports.PositionSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const responses_1 = require("../../responses");
const user_1 = require("../user");
Object.defineProperty(exports, "UserFindFilterParamsSchema", { enumerable: true, get: function () { return user_1.UserFindFilterParamsSchema; } });
const baseEntity_1 = require("../baseEntity");
// Base definitions
exports.PositionSchema = typebox_1.Type.Object({ name: typebox_1.Type.String({ minLength: 2, maxLength: 100 }) });
exports.PositionFindOrderParams = typebox_1.Type.Object({
    name: typebox_1.Type.String({}),
});
// Params
exports.InternalPositionCreateUpdateSchema = typebox_1.Type.Composite([
    exports.PositionSchema,
    typebox_1.Type.Object({ organization_id: typebox_1.Type.Integer() }),
]);
exports.PositionCreateUpdateSchema = exports.PositionSchema;
exports.PositionsFindFilterParamsSchema = typebox_1.Type.Partial(typebox_1.Type.Object({
    name: typebox_1.Type.String({ minLength: 2 }),
}));
exports.PositionsFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.PositionsFindFilterParamsSchema,
    order: exports.PositionFindOrderParams,
});
exports.UsersPositionFindParamsSchema = (0, responses_1.FindParams)({
    filters: user_1.UserFindFilterParamsSchema,
    order: user_1.UserFindOrderParams,
});
exports.PositionAssignToStaffParamsSchema = typebox_1.Type.Object({
    staff_ids: typebox_1.Type.Array(typebox_1.Type.Integer()),
});
exports.PositionQueryParamsSchema = typebox_1.Type.Object({
    // TODO: Do uuid
    id: typebox_1.Type.Integer(),
});
// Response
exports.PositionListItemSchema = typebox_1.Type.Composite([
    baseEntity_1.BaseEntitySchema,
    exports.PositionSchema,
    typebox_1.Type.Object({ count: typebox_1.Type.Integer() }),
]);
exports.PositionFindResponseSchema = (0, responses_1.PaginatedList)(exports.PositionListItemSchema);
exports.UserPositionFindItemSchema = typebox_1.Type.Intersect([
    user_1.UserSchemaResponse,
    typebox_1.Type.Object({ staff_id: typebox_1.Type.Integer() }),
]);
exports.UsersPositionFindResponseSchema = (0, responses_1.PaginatedList)(exports.UserPositionFindItemSchema);
exports.CreateUpdateResponseSchema = typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.PositionSchema]);
exports.PositionDetailsResponseSchema = typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.PositionSchema]);
