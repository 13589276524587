"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JSONSchemaTypes = void 0;
var JSONSchemaTypes;
(function (JSONSchemaTypes) {
    JSONSchemaTypes["CONST"] = "const";
    JSONSchemaTypes["FORMAT"] = "format";
    JSONSchemaTypes["MIN_LENGTH"] = "minLength";
    JSONSchemaTypes["MAX_LENGTH"] = "maxLength";
})(JSONSchemaTypes || (exports.JSONSchemaTypes = JSONSchemaTypes = {}));
