"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssignRemoveUsersToRoleParams = exports.RoleFindResponseSchema = exports.RolesArrayResponseSchema = exports.RoleCreateUpdateSchema = exports.RoleCreateUpdateFormSchema = exports.UsersByRoleFindParamsSchema = exports.RoleFindParamsSchema = exports.RoleFindOrderParams = exports.RoleFindFilterParams = exports.RoleIdsQueryParamsSchema = exports.RoleParamsSchema = exports.GetRolesQuerySchema = exports.RolesListSchemaResponse = exports.RoleSchemaResponse = exports.RoleSchema = exports.RoleDescriptionSchema = exports.RoleNameSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const responses_1 = require("../../responses");
const user_validation_rules_1 = require("../../validation-rules/user.validation-rules");
const baseEntity_1 = require("../baseEntity");
const user_1 = require("../user");
const permission_1 = require("../permission");
exports.RoleNameSchema = typebox_1.Type.String({
    minLength: 2,
    maxLength: 45,
    pattern: user_validation_rules_1.LATIN_LETTERS_NUMBERS_SYMBOLS.source,
    errorMessage: 'Name must be between 2 and 45 characters and contain only letters, numbers, spaces, and hyphens.',
});
exports.RoleDescriptionSchema = typebox_1.Type.Optional(typebox_1.Type.String({
    maxLength: 350,
    pattern: user_validation_rules_1.LATIN_LETTERS_NUMBERS_SYMBOLS.source,
    errorMessage: 'Description can contain letters, numbers, symbols, and spaces.',
}));
exports.RoleSchema = typebox_1.Type.Object({
    name: exports.RoleNameSchema,
    description: exports.RoleDescriptionSchema,
    permissions: typebox_1.Type.Array(permission_1.PermissionSchema),
    is_editable: typebox_1.Type.Boolean(),
});
exports.RoleSchemaResponse = typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.RoleSchema], { additionalProperties: false });
exports.RolesListSchemaResponse = typebox_1.Type.Composite([exports.RoleSchemaResponse, typebox_1.Type.Object({ count: typebox_1.Type.Integer() })]);
// Find params
exports.GetRolesQuerySchema = typebox_1.Type.Object({
    search: typebox_1.Type.Optional(typebox_1.Type.String()),
    sort: typebox_1.Type.Optional(typebox_1.Type.Enum(responses_1.OrderDirection)),
});
exports.RoleParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
});
exports.RoleIdsQueryParamsSchema = typebox_1.Type.Object({
    roles: typebox_1.Type.Array(typebox_1.Type.Integer()),
});
exports.RoleFindFilterParams = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.RoleSchemaResponse, ['name']));
exports.RoleFindOrderParams = typebox_1.Type.Pick(exports.RoleSchemaResponse, ['name', 'created_at', 'updated_at']);
exports.RoleFindParamsSchema = (0, responses_1.FindParams)({
    filters: exports.RoleFindFilterParams,
    order: exports.RoleFindOrderParams,
});
exports.UsersByRoleFindParamsSchema = (0, responses_1.FindParams)({
    filters: user_1.UserFindFilterParamsSchema,
    order: user_1.UserFindOrderParams,
});
// Create || update
exports.RoleCreateUpdateFormSchema = typebox_1.Type.Pick(exports.RoleSchema, ['name', 'description']);
exports.RoleCreateUpdateSchema = exports.RoleSchema;
exports.RolesArrayResponseSchema = typebox_1.Type.Array(exports.RoleSchemaResponse, { description: 'Array of roles' });
exports.RoleFindResponseSchema = (0, responses_1.PaginatedList)(exports.RolesListSchemaResponse);
// Actions
exports.AssignRemoveUsersToRoleParams = typebox_1.Type.Array(typebox_1.Type.Integer());
