"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RolesPermissionsResponse = exports.PermissionsSchema = exports.PermissionSchema = exports.EditableSubjectsSchema = exports.SubjectsSchema = exports.EditableActionsSchema = exports.PermissionActionsSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.PermissionActionsSchema = typebox_1.Type.Union([
    typebox_1.Type.Literal('read'),
    typebox_1.Type.Literal('edit'),
    typebox_1.Type.Literal('delete'),
    typebox_1.Type.Literal('manage'),
]);
exports.EditableActionsSchema = typebox_1.Type.Union([typebox_1.Type.Literal('read'), typebox_1.Type.Literal('edit'), typebox_1.Type.Literal('delete')]);
exports.SubjectsSchema = typebox_1.Type.Union([
    typebox_1.Type.Literal('RolesAndPermissionsManagement'),
    typebox_1.Type.Literal('StaffPositionManagement'),
    typebox_1.Type.Literal('UserFullProfileManagement'),
    typebox_1.Type.Literal('CoursesManagement'),
    typebox_1.Type.Literal('all'),
]);
exports.EditableSubjectsSchema = typebox_1.Type.Union([
    typebox_1.Type.Literal('RolesAndPermissionsManagement'),
    typebox_1.Type.Literal('StaffPositionManagement'),
    typebox_1.Type.Literal('UserFullProfileManagement'),
    typebox_1.Type.Literal('CoursesManagement'),
]);
exports.PermissionSchema = typebox_1.Type.Object({
    subject: exports.SubjectsSchema,
    actions: typebox_1.Type.Array(exports.PermissionActionsSchema),
});
exports.PermissionsSchema = typebox_1.Type.Record(typebox_1.Type.String(), typebox_1.Type.Array(typebox_1.Type.String()));
exports.RolesPermissionsResponse = typebox_1.Type.Object({
    permissions: exports.PermissionsSchema,
});
