import { FC } from 'react'
import { format } from 'date-fns'
import { DateHeaderProps } from 'react-big-calendar'
import { useTranslation } from 'react-i18next'

const MonthDateHeader: FC<DateHeaderProps> = ({ date }) => {
  const { t } = useTranslation('calendar')

  const dayNumber = format(date, 'd')
  const monthNameGenitive = t(`months_genitive_${date.getMonth()}`)

  return (
    <div className="flex p-1">
      <span className="uppercase text-gray-400">
        {dayNumber} {monthNameGenitive}
      </span>
    </div>
  )
}

export default MonthDateHeader
