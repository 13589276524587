"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GradesFindResponseSchema = exports.GradeBaseSchema = exports.GradeSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.GradeSchema = typebox_1.Type.Integer({
    minimum: 1,
    maximum: 11,
});
exports.GradeBaseSchema = typebox_1.Type.Object({
    index: exports.GradeSchema,
});
exports.GradesFindResponseSchema = typebox_1.Type.Object({
    grades: typebox_1.Type.Array(exports.GradeSchema),
});
