import {
  UserIcon,
  UserCircleIcon,
  QuestionMarkCircleIcon,
  ArrowLeftEndOnRectangleIcon,
} from '@heroicons/react/24/outline'
import { useService } from 'src/shared/hooks/di'
import { useNavigate } from 'react-router-dom'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'src/shared/constants/auth'
import { useLocalStorage } from 'src/shared/hooks/useLocalStorage'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { ROLE_PERMISSIONS_QUERY_KEYS } from 'src/shared/api/roles/constants'
import { USERS_QUERY_KEYS } from 'src/shared/api/users/constants'
import { s3FileUrlBuilder } from 'src/shared/utils/s3FileUrlBuilder.ts'
import ROUTES from 'src/app/router/constants'
import { generatePath } from 'src/shared/utils/generatePath'
import { MeStore } from 'src/modules/UsersModule/User/stores/MeStore'

const UserCardPopover = () => {
  const navigate = useNavigate()
  const { removeItem } = useLocalStorage()
  const { me, clean } = useService(MeStore)
  const queryClient = useQueryClient()
  const { t } = useTranslation('menu')

  const goToProfile = () => {
    if (!me?.id) {
      throw new Error('No id to navigate')
    }
    navigate(generatePath(ROUTES.USERS.PROFILE, { id: me.id }))
  }

  const onLogoutClick = () => {
    removeItem(ACCESS_TOKEN_KEY)
    removeItem(REFRESH_TOKEN_KEY)
    clean()
    queryClient.removeQueries({
      queryKey: USERS_QUERY_KEYS.me(),
    })
    queryClient.removeQueries({
      queryKey: ROLE_PERMISSIONS_QUERY_KEYS.self,
    })

    navigate(ROUTES.AUTH.SIGN_IN, { replace: true })
  }

  return (
    <div className='flex w-full min-w-72 flex-col gap-3 p-4 text-sm shadow-lg'>
      <div className='flex flex-col gap-1'>
        <div>{t('loggedAs')}</div>
        <div className='flex items-center gap-2'>
          {me?.avatar_url_path ? (
            <img src={s3FileUrlBuilder(me?.avatar_url_path)} alt='avatar' className='size-10 rounded-full' />
          ) : (
            <UserCircleIcon className='size-8' />
          )}
          <div className='flex flex-col'>
            <span className='font-semibold'>{`${me?.first_name} ${me?.last_name}`}</span>
            <span className='text-gray-500'>{me?.system_role}</span>
          </div>
        </div>
        <button className='text-left'>+ {t('logInNewAccount')}</button>
      </div>
      <hr />
      <button className='flex items-center gap-3' onClick={goToProfile}>
        <UserIcon className='size-5' />
        {t('myProfile')}
      </button>
      <button className='flex items-center gap-3'>
        <QuestionMarkCircleIcon className='size-5' />
        {t('helpCenter')}
      </button>
      <button className='flex items-center gap-3 text-red-600' onClick={onLogoutClick}>
        <ArrowLeftEndOnRectangleIcon className='size-5' />
        {t('logOut')}
      </button>
    </div>
  )
}

export default UserCardPopover
