import { injectable } from 'tsyringe'

// TODO: Move to common services package
@injectable()
export class IndexedDBConfigService {
  constructor(
    private _name: string,
    private _version: number,
    private _dataStoreName: string,
  ) {}

  change = (name: string, version: number, dataStoreName: string) => {
    this._name = name
    this._version = version
    this._dataStoreName = dataStoreName
  }

  changeDataStore = (dataStoreName: string) => {
    this._dataStoreName = dataStoreName
  }

  get dbName() {
    return this._name
  }

  get dbVersion() {
    return this._version
  }

  get dataStoreName() {
    return this._dataStoreName
  }
}

export const indexedDBConfigFactory = (name: string, version: number, dataStoreName: string) => () =>
  new IndexedDBConfigService(name, version, dataStoreName)
