import { NavLink } from 'react-router-dom'
import { LogoIcon, SimpleLogoIcon } from '@repo/ui/icons'
import { useAsideContext } from '@repo/ui/PrivateLayout'
import ROUTES from 'src/app/router/constants'

const Logo = () => {
  const isAsideOpen = useAsideContext()

  return (
    <div className='flex justify-center overflow-hidden py-5'>
      <NavLink to={ROUTES.HOME}>{isAsideOpen ? <LogoIcon /> : <SimpleLogoIcon />}</NavLink>
    </div>
  )
}

export default Logo
