export const Namespaces = {
  Validation: 'validation',
  SignIn: 'sign-in',
} as const

export const LocalizationLanguages = {
  en: 'en',
  uk: 'uk',
} as const

export const LocalizationKey = 'locale' as const
export const DEFAULT_LANGUAGE = 'uk'
