import { inject, injectable } from 'tsyringe'
import { IndexedDBConfigService } from './config'

// TODO: Move to common services package
@injectable()
export class IndexedDBService {
  constructor(@inject(IndexedDBConfigService) private _indexedDBConfigService: IndexedDBConfigService) {}

  private open = async (): Promise<IDBDatabase> => {
    return new Promise<IDBDatabase>((resolve, reject) => {
      const request = indexedDB.open(this._indexedDBConfigService.dbName, this._indexedDBConfigService.dbVersion)

      request.onerror = () => {
        console.error(`[IndexedDB]: Cannot open connection to DB due to error: ${request.error?.message}`)
        reject(request.error)
      }

      request.onsuccess = () => {
        const db = request.result

        db.onversionchange = () => {
          db.close()
        }

        resolve(request.result)
      }

      request.onupgradeneeded = () => {
        const db = request.result
        db.createObjectStore(this._indexedDBConfigService.dataStoreName)
      }
    })
  }

  private _openTransaction = async (mode: IDBTransactionMode) => {
    const db = await this.open()
    const transaction = db.transaction([this._indexedDBConfigService.dataStoreName], mode)

    return { db, store: transaction.objectStore(this._indexedDBConfigService.dataStoreName) }
  }

  storeData = async (key: string, data: unknown): Promise<void> => {
    const { db, store } = await this._openTransaction('readwrite')

    return new Promise<void>((resolve, reject) => {
      const request = store.put(data, key)

      request.onsuccess = () => {
        db.close()
        resolve()
      }

      request.onerror = () => {
        console.error(`[IndexedDB]: Cannot store data to DB due to error: ${request.error?.message}`)
        db.close()
        reject(request.error)
      }
    })
  }

  getData = async <T>(key: string): Promise<T> => {
    const { db, store } = await this._openTransaction('readonly')

    return new Promise<T>((resolve, reject) => {
      const request = store.get(key)

      request.onsuccess = (event: Event) => {
        const storedData = (event.target as IDBRequest).result

        db.close()
        resolve(storedData)
      }

      request.onerror = () => {
        console.error(`[IndexedDB]: Cannot get data to DB due to error: ${request.error?.message}`)
        db.close()
        reject(request.error)
      }
    })
  }
}
