import { injectable, singleton } from 'tsyringe'
import { action, computed, makeObservable, observable } from 'mobx'
import { ActionType, SubjectType } from '@repo/contracts'
import { AbilityBuilder, AbilityClass, InferSubjects, PureAbility } from '@casl/ability'

export type InferredSubjects = InferSubjects<SubjectType>

export type AppAbility = PureAbility<[ActionType, SubjectType]>

@singleton()
@injectable()
export class AbilityService {
  @observable private _abilities: AppAbility | null = null

  constructor() {
    makeObservable(this)
  }

  @action.bound
  defineUserAbility(abilities: Record<SubjectType, ActionType[]>) {
    const { can, build } = new AbilityBuilder(PureAbility as AbilityClass<AppAbility>)
    Object.entries(abilities).forEach(([subject, actions]) => {
      actions.forEach((action: ActionType) => {
        can(action, subject as InferredSubjects)
      })
    })

    this._abilities = build()
  }

  @action.bound
  clear() {
    this._abilities = null
  }

  @computed
  get abilities() {
    return this._abilities
  }
}
