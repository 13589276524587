import { FC, useCallback } from 'react'
import ModuleSpinner from '@repo/ui/ModuleSpinner'
import useAuthRedirect from 'src/shared/hooks/useAuthRedirect.ts'
import { Outlet, useNavigate } from 'react-router-dom'
import ROUTES from 'src/app/router/constants'

interface PublicLayoutProps {
  shouldRedirectOnActiveSession?: boolean
}

const PublicLayout: FC<PublicLayoutProps> = ({ shouldRedirectOnActiveSession }) => {
  const navigate = useNavigate()

  const callbackRedirect = useCallback(
    (expiredAt: number) => {
      const isExpired = Date.now() >= expiredAt
      if (isExpired || shouldRedirectOnActiveSession === false) return false
      navigate(ROUTES.HOME)

      return true
    },
    [navigate, shouldRedirectOnActiveSession],
  )
  const isLocalStorageScanning = useAuthRedirect({ callback: callbackRedirect })

  if (isLocalStorageScanning) return <ModuleSpinner />

  return (
    <div className='flex min-h-screen flex-col'>
      <div className='flex size-full grow justify-between'>
        <div className='flex w-full flex-col items-center justify-center p-5 lg:grow-0 lg:basis-2/3'>
          <div className='flex size-full max-w-lg flex-col justify-center'>
            <Outlet />
          </div>
        </div>
        <div className='relative hidden lg:flex lg:basis-1/3'>
          <img src='/assets/side-bg.png' alt='Decorative' className='absolute size-full object-cover' />
        </div>
      </div>
    </div>
  )
}

export default PublicLayout
