import ROUTES from '../constants'
import { lazy, Suspense } from 'react'
import ModuleSpinner from '@repo/ui/ModuleSpinner'

const CategoriesListPage = lazy(() => import('src/pages/CategoriesPages/CategoriesListPage'))
const TagsListPage = lazy(() => import('src/pages/TagsPages/TagsListPage'))
const DictionariesPage = lazy(() => import('src/pages/DictionariesPage'))

export default [
  {
    path: ROUTES.DICTIONARIES,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <DictionariesPage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.CATEGORIES.LIST,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <CategoriesListPage />
      </Suspense>
    ),
  },
  {
    path: ROUTES.TAGS.LIST,
    element: (
      <Suspense fallback={<ModuleSpinner />}>
        <TagsListPage />
      </Suspense>
    ),
  },
]
