import { useLocalStorage } from 'src/shared/hooks/useLocalStorage.ts'
import useJWTPayload from 'src/shared/hooks/useJWTPayload.ts'
import { ACCESS_TOKEN_KEY } from 'src/shared/constants/auth.ts'
import { useQuery } from '@tanstack/react-query'
import { useService } from 'src/shared/hooks/di'
import { RolesAPI } from '../api/roles/api'
import { ROLE_PERMISSIONS_QUERY_KEYS } from '../api/roles/constants'
import { AbilityService } from '../services/AbilityService'

const useFetchPermissions = () => {
  const { getItem } = useLocalStorage()
  const getJWTPayload = useJWTPayload()
  const { defineUserAbility } = useService(AbilityService)
  const rolesAPI = useService(RolesAPI)

  const { isLoading } = useQuery({
    queryKey: ROLE_PERMISSIONS_QUERY_KEYS.self,
    queryFn: async () => {
      const accessToken = getItem(ACCESS_TOKEN_KEY)
      const {
        user: { roles },
      } = getJWTPayload(accessToken)
      const response = await rolesAPI.getPermissionsByRoles({ roles })

      defineUserAbility(response.data.permissions)

      return response
    },
  })

  return isLoading
}

export default useFetchPermissions
