"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarPlanEventsDeletePayloadSchema = exports.CalendarPlanEventsDeleteByEventId = exports.CalendarPlanEventsDeleteByEventChainId = exports.CalendarPlanEventsDeleteByCourseId = exports.CalendarPlanEventsFindSchema = exports.CalendarPlanEventsFindPayloadSchema = exports.CreateCalendarEventsFormSchema = exports.CreateCalendarEventsExpandedFormSchema = exports.CreateCalendarEventsBaseFormSchema = exports.CreateCalendarEventsSchema = exports.CalendarPlanEventSchema = exports.CalendarPlanCoursesFindResponseSchema = exports.CalendarPlanCourseFindItem = exports.CalendarPlanCreateUpdateFormSchema = exports.CalendarPlanFindResponseSchema = exports.CalendarPlanFindItemResponse = exports.BulkDeleteCalendarPlanSchema = exports.CalendarPlanFindParamsSchema = exports.CalendarPlanFindOrderSchema = exports.CalendarPlanFindFilterParamsSchema = exports.CalendarPlanChangeStatusParamsSchema = exports.CalendarPlanParamsSchema = exports.CalendarPlanSchemaResponse = exports.CalendarPlanCreateUpdateSchema = exports.CalendarPlanSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const responses_1 = require("../../responses");
const common_1 = require("../../common");
const course_1 = require("../course");
const baseEntity_1 = require("../baseEntity");
const constants_1 = require("../../constants");
const utils_1 = require("../../utils");
const types_1 = require("./types");
const constants_2 = require("./constants");
exports.CalendarPlanSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    name: typebox_1.Type.String({ minLength: 2, maxLength: 100 }),
    status: typebox_1.Type.Enum(course_1.COURSE_STATUSES, { default: course_1.COURSE_STATUSES.ACTIVE }),
    period_start: typebox_1.Type.String({ format: 'date-time' }),
    period_end: typebox_1.Type.String({ format: 'date-time' }),
    first_semester_start: (0, common_1.Nullable)(typebox_1.Type.String({ format: 'date-time' })),
    first_semester_end: (0, common_1.Nullable)(typebox_1.Type.String({ format: 'date-time' })),
    second_semester_start: (0, common_1.Nullable)(typebox_1.Type.String({ format: 'date-time' })),
    second_semester_end: (0, common_1.Nullable)(typebox_1.Type.String({ format: 'date-time' })),
    study_plan_id: typebox_1.Type.Integer(),
}, { additionalProperties: false });
exports.CalendarPlanCreateUpdateSchema = typebox_1.Type.Omit(exports.CalendarPlanSchema, ['id', 'study_plan_id']);
exports.CalendarPlanSchemaResponse = typebox_1.Type.Composite([baseEntity_1.BaseEntitySchema, exports.CalendarPlanSchema]);
exports.CalendarPlanParamsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    calendarPlanId: typebox_1.Type.Integer(),
});
exports.CalendarPlanChangeStatusParamsSchema = typebox_1.Type.Object({
    status: typebox_1.Type.Enum(course_1.COURSE_STATUSES),
});
exports.CalendarPlanFindFilterParamsSchema = typebox_1.Type.Object({});
exports.CalendarPlanFindOrderSchema = typebox_1.Type.Partial(typebox_1.Type.Pick(exports.CalendarPlanSchemaResponse, [
    'name',
    'created_at',
    'status',
]));
exports.CalendarPlanFindParamsSchema = (0, responses_1.FindParams)({
    order: exports.CalendarPlanFindOrderSchema,
});
exports.BulkDeleteCalendarPlanSchema = typebox_1.Type.Object({
    calendar_plan_ids: typebox_1.Type.Array(typebox_1.Type.Integer()),
});
exports.CalendarPlanFindItemResponse = typebox_1.Type.Composite([
    exports.CalendarPlanSchemaResponse,
    typebox_1.Type.Object({
        planned: typebox_1.Type.Integer(),
        needPlan: typebox_1.Type.Integer(),
    }),
]);
exports.CalendarPlanFindResponseSchema = (0, responses_1.PaginatedList)(exports.CalendarPlanFindItemResponse);
exports.CalendarPlanCreateUpdateFormSchema = typebox_1.Type.Object({
    name: typebox_1.Type.String({ minLength: 2, maxLength: 100 }),
    status: common_1.FormLookupOption,
    calendarPlanPeriod: common_1.DateRangeArray,
    firstSemesterPeriod: (0, common_1.Nullable)(common_1.DateRangeArray),
    secondSemesterPeriod: (0, common_1.Nullable)(common_1.DateRangeArray),
});
exports.CalendarPlanCourseFindItem = typebox_1.Type.Object({
    course_version_id: typebox_1.Type.Integer(),
    course_name: typebox_1.Type.String(),
    planned: typebox_1.Type.Array(typebox_1.Type.Integer()),
    needPlan: typebox_1.Type.Array(typebox_1.Type.Integer()),
    semesters_use: typebox_1.Type.Boolean(),
});
exports.CalendarPlanCoursesFindResponseSchema = typebox_1.Type.Array(exports.CalendarPlanCourseFindItem);
exports.CalendarPlanEventSchema = typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    course_version_id: typebox_1.Type.Integer(),
    lesson_id: typebox_1.Type.Integer(),
    topic_id: typebox_1.Type.Integer(),
    description: typebox_1.Type.String({ maxLength: constants_1.POSTGRES_MAX_VARCHAR_SIZE }),
    event_chain_id: typebox_1.Type.String({ format: 'uuid' }),
    is_full_day: typebox_1.Type.Boolean({ default: false }),
    rrule: (0, common_1.Nullable)(typebox_1.Type.String()), // TODO: Maybe in separate table?;
    event_date: typebox_1.Type.String({ format: 'date-time' }),
    start_datetime: typebox_1.Type.String({ format: 'date-time' }),
    end_datetime: typebox_1.Type.String({ format: 'date-time' }),
    duration: typebox_1.Type.Integer({ minimum: constants_2.DURATION_MIN_VALUE }), // minutes
});
exports.CreateCalendarEventsSchema = typebox_1.Type.Composite([
    typebox_1.Type.Pick(exports.CalendarPlanEventSchema, [
        'course_version_id',
        'is_full_day',
        'description',
        'rrule',
        'duration',
        'event_date',
    ]),
]);
exports.CreateCalendarEventsBaseFormSchema = typebox_1.Type.Pick(exports.CalendarPlanEventSchema, [
    'course_version_id',
    'is_full_day',
    'description',
    'rrule',
    'duration',
    'event_date',
]);
exports.CreateCalendarEventsExpandedFormSchema = typebox_1.Type.Object({
    timeStart: typebox_1.Type.Optional(typebox_1.Type.String({ pattern: '^([01]\\d|2[0-3]):([0-5]\\d)$', errorMessage: 'wrongTimeFormat' })),
    timeEnd: typebox_1.Type.Optional(typebox_1.Type.String({ pattern: '^([01]\\d|2[0-3]):([0-5]\\d)$', errorMessage: 'wrongTimeFormat' })),
    freq: (0, common_1.Nullable)(common_1.FormLookupOption),
    endDate: (0, common_1.Nullable)(typebox_1.Type.String({ format: 'date' })),
    endAfterTimes: (0, common_1.Nullable)(typebox_1.Type.Number()),
    interval: typebox_1.Type.Optional(typebox_1.Type.Number({ minimum: constants_2.INTERVAL_MIN_VALUE })),
});
exports.CreateCalendarEventsFormSchema = typebox_1.Type.Composite([
    exports.CreateCalendarEventsBaseFormSchema,
    exports.CreateCalendarEventsExpandedFormSchema,
]);
exports.CalendarPlanEventsFindPayloadSchema = typebox_1.Type.Object({
    filter: typebox_1.Type.Object({
        startDate: typebox_1.Type.String({ format: 'date-time' }),
        endDate: typebox_1.Type.String({ format: 'date-time' }),
    }),
});
exports.CalendarPlanEventsFindSchema = typebox_1.Type.Array(typebox_1.Type.Object({
    id: typebox_1.Type.Integer(),
    lesson_id: typebox_1.Type.Integer(),
    lesson_name: typebox_1.Type.String(),
    topic_id: typebox_1.Type.Integer(),
    topic_name: typebox_1.Type.String(),
    event_date: typebox_1.Type.String({ format: 'date-time' }),
    course_id: typebox_1.Type.Integer(),
    description: typebox_1.Type.String(),
    event_chain_id: typebox_1.Type.String({ format: 'uuid' }),
    is_full_day: typebox_1.Type.Boolean(),
    rrule: (0, common_1.Nullable)(typebox_1.Type.String()),
    start_datetime: typebox_1.Type.String({ format: 'date-time' }),
    end_datetime: typebox_1.Type.String({ format: 'date-time' }),
    duration: typebox_1.Type.Integer(),
}));
exports.CalendarPlanEventsDeleteByCourseId = typebox_1.Type.Object({ id: typebox_1.Type.Integer(), type: typebox_1.Type.Literal(types_1.CalendarPlanDeleteType.CourseId) }, { additionalProperties: false });
exports.CalendarPlanEventsDeleteByEventChainId = typebox_1.Type.Object({ id: typebox_1.Type.String({ format: 'uuid' }), type: typebox_1.Type.Literal(types_1.CalendarPlanDeleteType.EventChainId) }, { additionalProperties: false });
exports.CalendarPlanEventsDeleteByEventId = typebox_1.Type.Object({ id: typebox_1.Type.Integer(), type: typebox_1.Type.Literal(types_1.CalendarPlanDeleteType.EventId) }, { additionalProperties: false });
exports.CalendarPlanEventsDeletePayloadSchema = (0, utils_1.OneOf)([exports.CalendarPlanEventsDeleteByCourseId, exports.CalendarPlanEventsDeleteByEventChainId, exports.CalendarPlanEventsDeleteByEventId], {
    type: 'object',
    discriminator: { propertyName: 'type' },
    required: ['type'],
});
