import { useDisclosure } from '@repo/ui/useDisclosure'
import { ReactNode } from 'react'
import { MenuItemButton } from './MenuItemButton'
import { MenuCollapseContainer } from './MenuCollapseContainer'
import { useTranslation } from 'react-i18next'
import { ActionType, SubjectType } from '@repo/contracts'
import useCheckPermissions from 'src/shared/hooks/useCheckPermissions.ts'
import { useAsideContext } from '@repo/ui/PrivateLayout'

interface MenuItemCollapseProps {
  onClick?: () => void
  label: string
  children: ReactNode
  selected?: boolean
  leftIcon?: ReactNode
  permissions?: Partial<Record<SubjectType, ActionType[]>>
}

export const MenuItemCollapse = ({ children, label, leftIcon, permissions }: MenuItemCollapseProps) => {
  const { isOpen, toggle } = useDisclosure()
  const isAsideOpen = useAsideContext()
  const { t } = useTranslation('menu')
  const canSee = useCheckPermissions(permissions)
  if (!canSee) return null

  return (
    <div>
      <MenuItemButton leftIcon={leftIcon} onClick={toggle} isMenuOpen={isOpen}>
        {t(label)}
      </MenuItemButton>
      {isAsideOpen && <MenuCollapseContainer isOpen={isOpen}>{children}</MenuCollapseContainer>}
    </div>
  )
}
