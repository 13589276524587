import { useEffect, useState } from 'react'
import { useLocalStorage } from 'src/shared/hooks/useLocalStorage.ts'
import useJWTPayload from 'src/shared/hooks/useJWTPayload.ts'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'src/shared/constants/auth.ts'

type UseUnauthorizedRedirectProps = {
  callback?: (expiredAt: number) => boolean
  emptyStoreCallback?: () => void
}

const useAuthRedirect = ({ callback, emptyStoreCallback }: UseUnauthorizedRedirectProps) => {
  const [isLocalStorageScanning, setIsLocalStorageScanning] = useState(true)
  const { getItem } = useLocalStorage()
  const getJWTPayload = useJWTPayload()

  useEffect(() => {
    const accessToken = getItem(ACCESS_TOKEN_KEY)
    const refreshToken = getItem(REFRESH_TOKEN_KEY)

    if (!accessToken || !refreshToken) {
      emptyStoreCallback?.()
      setIsLocalStorageScanning(false)

      return
    }

    if (accessToken && refreshToken) {
      const { expiredAt } = getJWTPayload(accessToken)
      if (callback?.(expiredAt)) {
        return
      }
    }

    setIsLocalStorageScanning(false)
  }, [callback, getItem, getJWTPayload, emptyStoreCallback])

  return isLocalStorageScanning
}

export default useAuthRedirect
