import { FC, Suspense } from 'react'
import { ErrorBoundary } from '@repo/ui/ErrorBoundary'
import ModuleSpinner from '@repo/ui/ModuleSpinner'

const HomePage: FC = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<ModuleSpinner />}>
        <p>Home</p>
      </Suspense>
    </ErrorBoundary>
  )
}

export default HomePage
