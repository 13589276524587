import { ChangePasswordParamsSchemaType, PairTokenType, PasswordResetParamsSchemaType } from '@repo/contracts'
import { apiClient } from 'src/shared/api/clients'
import { injectable, singleton } from 'tsyringe'

@singleton()
@injectable()
export class AuthAPI {
  constructor() {}

  refreshToken(refreshToken: string) {
    return apiClient.post<PairTokenType>('auth/refresh', { refreshToken })
  }

  resetPassword(data: ChangePasswordParamsSchemaType) {
    return apiClient.post<PairTokenType>('auth/reset-password', data)
  }

  recoverPassword(data: PasswordResetParamsSchemaType) {
    return apiClient.post<PairTokenType>('auth/password-recovery', data)
  }
}
